.switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 28px;
  background: white;
  border-radius: 40px;
  border: none;
  appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.switch::before {
  content: '';
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 100%;
  transition: all 0.2s;
}

.switch.on {
  background-color: var(--color-primary-100);
}

.switch.off {
  background-color: #f1eff5;
}

.switch.on::before {
  transform: translate3d(32px, 0, 0);
}

.labelText {
  font-size: 10px;
  /* chrome等のブラウザでは10px以下のfont-sizeを指定しても10pxになってしまうため、transformで縮小させている */
  transform: scale(0.8);
  transform-origin: center;
  font-family: var(--font-HiraginoSansW3);
}

.on .labelText {
  text-align: left;
  padding-left: 4px;
  color: var(--color-light);
}

.off .labelText {
  text-align: right;
  padding-right: 1 px;
  color: #859194;
}
