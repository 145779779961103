.fontPointReason {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-gray-100);
}

.fontPointDetail {
  font-family: var(--font-NotoSans);
  font-size: 14px;
  color: #f36c18;
}

@media screen and (max-width: 375px) {
  .fontPointReason,
  .fontPointDetail {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .fontPointReason,
  .fontPointDetail {
    font-size: 11px;
  }
}

.pointSummary {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 72px;

  background-color: rgba(243, 100, 12, 0.1);
}

.pointReason {
  padding-left: var(--spacing-m);
  position: relative;
  composes: fontPointReason;
}

.pointReason::before {
  content: '';
  background-image: url('global/images/icon-check-inverted.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pointDetail {
  padding-left: var(--spacing-m);
  position: relative;
  composes: fontPointDetail;
}

.pointDetail::before {
  content: '';
  background-image: url('global/images/icon-point.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
