.category {
  display: flex;
  align-items: center;
  height: 56px;
  text-align: left;
  background-color: var(--color-background);
}

.category .text {
  padding-left: var(--spacing-s);
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  color: var(--color-base-dark);
}
