.noticeContainer {
  background-color: var(--color-light);
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  color: var(--color-base-dark-sub);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  padding: 20px 46px 20px 16px;

  position: relative;
}

.showContainer {
  display: block;
}

.closeContainer {
  display: none;
}

.noticeIcon {
  content: url('../../images/icon-notice.svg');
  width: 48px;
  height: 48px;
}

.closeBtn {
  content: url('../../images/icon-close.svg');
  border-radius: 100%;
  background-color: #000;
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  appearance: none;
  cursor: pointer;
}
