.container {
  overflow: hidden;
  position: relative;
  width: 100%;

  display: flex;
}

.leftArea {
  display: table;
}

.leftArea .leftAreaChild {
  display: table-cell;
  vertical-align: middle;
}

.rewardIconArea {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  background-color: #f4f5f7;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
}

.rewardIconArea .icon {
  margin: auto;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
}

.rewardIconArea .icon img {
  width: auto;
  height: 100%;
}

.rewardIconArea .value {
  margin: auto;
  width: 36px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 10px;
  color: var(--color-base-dark);
}

.summaryArea {
  width: 100%;
  padding: 8px 0 8px 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.summaryArea .title {
  text-align: left;
  width: 100%;
  font-family: var(--font-HiraginoSansW6);
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-base-dark);
}

.summaryArea .underContentsArea {
  display: flex;
}

.underContentsArea .progressRateArea {
  width: 100%;
}

.progressRateArea .progressTextValue {
  text-align: end;
}

.progressTextValue .numerator {
  display: inline-block;
  font-family: var(--font-HiraginoSansW3);
  font-size: 16px;
  margin-right: 4px;
  color: var(--color-base-dark);
}

.progressTextValue .otherValue {
  display: inline-block;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #d0d2d5;
}

.progressRateArea .progressBar {
  height: 8px;
  width: 100%;
  text-align: left;
}
