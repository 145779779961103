.bottomSheetContainer {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  width: calc(100% - 32px);
  padding: 32px 16px 16px 16px;
  background-color: var(--color-light);
}

.textContainer {
  height: 100px;
  display: grid;
  white-space: pre-wrap;
}

.mainContent {
  font-size: 24px;
  text-align: center;
  font-family: var(--font-HiraginoSansW6);
  line-height: 1.17;
}

.subContent {
  font-size: 14px;
  font-family: var(--font-HiraginoSansW3);
  text-align: center;
  line-height: 1.71;
}

.copyright {
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 320px) {
  .textContainer {
    height: 90px;
  }

  .mainContent {
    font-size: 20px;
  }

  .subContent {
    font-size: 12px;
  }

  .copyright {
    font-size: 9px;
  }
}
