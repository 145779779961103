.container {
  padding: var(--spacing-s) 4px; /* 影が見切れる分だけのサイドpadding */
}

.title {
  font-family: var(--font-HiraginoSansW6);
  font-size: 22px;
  white-space: pre-wrap;
  color: var(--color-base-dark);
}

.termDescription {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

@media screen and (max-width: 320px) {
  .termDescription {
    font-size: 11px;
  }
}

.panel {
  padding: var(--spacing-s);
  margin: var(--spacing-s) auto var(--spacing-l) auto;
  width: 100%;
  border-radius: var(--spacing-s);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--color-light);
}

.hr {
  width: 100%;
  height: 1px;
  background-color: #ececec;
}
