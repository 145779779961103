.monthSection {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.monthSection:last-child {
  border-bottom: none;
}

.monthSectionItem {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: var(--color-base-dark-sub);
}

.monthSectionItem:nth-child(1) {
  width: 30%;
}

.monthSectionItem:nth-child(2) {
  width: 70%;
}

.daySection {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.daySection:last-child {
  border-bottom: none;
}

.daySectionItem {
  margin-top: 4px;
  margin-bottom: 4px;
}

.daySectionItem:nth-child(1) {
  width: 60%;
  padding: 0px;
}

.daySectionItem:nth-child(2) {
  width: 40%;
  padding: 0px;
  text-align: end;
}

.positivePoint {
  color: var(--color-base-dark-sub);
}

.negativePoint {
  padding-right: var(--spacing-s);
  color: var(--color-graph-red);
}
