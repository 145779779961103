.body {
  padding-top: 56px;
}

.text {
  height: 34px;
  font-size: 12px;
  text-align: center;
  line-height: 22px;
}

.highlightText {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

.errorMessage {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .bottomContainer {
    display: none;
  }
}
