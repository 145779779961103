.container {
  background-color: var(--color-background);
  min-height: 100%;
}

.questionSection {
  padding: var(--spacing-s);
}

.space {
  height: 48px;
}
