.sectionTitle {
  font-size: 18px;
  font-family: var(--font-HiraginoSansW6);
}

.lineChartPeriodWapper {
  display: flex;
  justify-content: space-between;
}

.lineChartPeriod {
  color: #738c89;
  font-family: var(--font-JosefinSans);
  font-size: 14px;
}

.lineChartPeriodSeparation {
  font-family: var(--font-HiraginoSansW6);
  padding: 0 4px;
}

.lineChartPeriodBtnWrapper {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  width: 8px;
}

.lineChartPeriodBtnActive {
  content: url('./arrow-active.svg');
  width: 100%;
}

.lineChartPeriodBtnInActive {
  content: url('./arrow-inactive.svg');
  width: 100%;
}

.lineChartPeriodBtnNext {
  transform: scale(-1, 1);
}
