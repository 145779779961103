.bottomContainer {
  position: fixed;
  z-index: 10;
  width: 100%;
}

/* これ以下に設定を下げる場合は、小さい端末でinputの入力欄がフッターに隠れる問題を考慮すること */
@media screen and (max-height: 340px) {
  .bottomContainer {
    display: none;
  }
}
