.container {
  display: block;
  font-size: 14px;
  line-height: 1.71;
  overflow: scroll;
  margin-top: var(--spacing-m);
  padding-bottom: 114px;
}

.container a {
  color: var(--color-primary-100);
  text-decoration: none;
}

.contentArea {
  padding: 0 var(--spacing-s) 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.checkbox {
  text-align: center;
  margin: 10px 0;
}

.fixedBottom {
  background: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  z-index: 10;
}

.buttonArea {
  padding: 8px var(--spacing-s) 58px;
  width: 100%;
}
