.container {
  padding: var(--spacing-m);
  scroll-margin-top: 48px;
}

.buttonArea {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  border-top: 1px solid rgba(236, 240, 247, 1);
  padding-top: 49px;
  padding-bottom: 58px;
}
