.headerBanner {
  position: relative;
}

.headerBanner img {
  width: 100%;
}

.container {
  background-color: var(--color-background);
  padding: var(--spacing-s) var(--spacing-s) 120px var(--spacing-s); /* NOTE: フロートボタン分下に空間を設けたいので多めに。ページの下端にボタンサイズを含めた形で領域を確保する必要がある都合上汎用的な値が適用できない。 */
}

.title {
  margin-top: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW6);
  font-size: 22px;
  line-height: 1.32;
}

.termDescription {
  margin-top: var(--spacing-m);
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.5;
}

.keywordArea {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  margin-right: -8px; /* 子要素の横並び端のマージンがはみ出るので調整 */
}

.keywordArea .keyword {
  border: solid 1px black;
  border-radius: 20px;
  padding: var(--spacing-xxs) var(--spacing-s);
  margin-right: var(--spacing-xxs);
  margin-bottom: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.5;
}

.hr {
  width: 100%;
  height: 1px;
  background-color: rgba(18, 18, 18, 0.1);
}

.description {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.5;
  margin: var(--spacing-l) 0;
  white-space: pre-wrap;
}

.missionPanelTitle {
  margin-top: var(--spacing-l);
  font-family: var(--font-HiraginoSansW6);
  font-size: 22px;
  line-height: 1.32;
  color: #121212;
}

.disclaimerText {
  margin-top: var(--spacing-s);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  white-space: pre-wrap;
  color: var(--color-base-dark-sub);
}

.noticeTitle {
  margin-top: 68px;
  margin-bottom: 20px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  text-align: center;
  color: var(--color-base-dark);
}

.noticeDescription {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  white-space: pre-wrap;
  color: var(--color-base-dark-sub);
}

.noticeContentsTitle {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-m);
  padding: var(--spacing-xxs) var(--spacing-xs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
  background-color: #e7e0d8;
}

.floatButton {
  margin: 0;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  position: fixed;
  z-index: 1;
}
