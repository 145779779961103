.fontOfferDetailTitle {
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  color: var(--color-base-dark);
}

.fontOfferDetailMessage {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
  white-space: pre-wrap;
}

.offerDetailTitle {
  composes: fontOfferDetailTitle;
  margin-bottom: var(--spacing-s);
}

.offerDetailMessage {
  composes: fontOfferDetailMessage;
}
