.scoreTrend {
  display: table-cell;
  height: 26px;
  font-size: 10px;
  font-family: var(--font-JosefinSans);
  position: relative;
  width: 20px;
}

.scoreTrend::after {
  border-radius: 100%;
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
}

.scoreTrend.up {
  color: var(--color-state-up);
}

.scoreTrend.down {
  color: var(--color-state-down);
}

.scoreTrend.unchanged {
  color: var(--color-state-unchanged);
}

.scoreTrend.up::after {
  content: url('../../../global/images/arrow-double-up.svg');
  background-color: var(--color-state-up);
}

.scoreTrend.down::after {
  content: url('../../../global/images/arrow-double-down.svg');
  background-color: var(--color-state-down);
}

.scoreTrend.unchanged::after {
  content: url('../../../global/images/arrow-double-unchanged.svg');
  background-color: var(--color-state-unchanged);
}
