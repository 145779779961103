.input {
  border: none;
  outline: 0;
  color: var(--color-base-dark);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  letter-spacing: 0.06px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

.inputNumLarge {
  font-size: 32px;
  line-height: 1.4;
  padding: 0 20px;
}

.inputNumSmall {
  font-size: 32px;
  padding: var(--spacing-xxs) 0 0 0;
}

input[type='tel']::placeholder {
  color: rgba(18, 18, 18, 0.3);
}
