.profilePageWrapper {
  padding: 16px 0 150px;
  background-color: var(--color-background);
}

.profilePageDescription {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.8;
  text-align: center;
}

.profilePageAttentionDescription {
  line-height: 1.8;
}

.sectionTitle {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);
}

.formItemList {
  background-color: var(--color-light);
  padding: 10px var(--spacing-s) 0;
}

@media screen and (max-height: 280px) {
  .bottomContainer {
    display: none;
  }
}
