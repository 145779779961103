.personalDataContainer {
  background-color: var(--color-background);
  padding: 60px 0 150px;
}

.selectButton {
  background-image: url('global/images/icon-public.svg');
  width: 60px;
  height: 28px;
  background-color: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
}

.sectionWrapper {
  padding: 12px var(--spacing-s);
}

.sectionWrapperHighlight {
  background-color: #d5e9e6;
}

.sectionTitle {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);

  /* アンカーリンクで遷移した際の位置を調整 */
  margin-top: -70px;
  padding-top: 70px;
}

.sectionTitleHighlight {
  color: #153f3b;
  font-family: var(--font-HiraginoSansW3);
}

.itemWrapper {
  background-color: var(--color-light);
  font-family: var(--font-NotoSans);
  font-size: 14px;
  font-weight: 300;
  padding: 20px var(--spacing-s);
}

.itemTitle {
  color: var(--color-base-dark-sub);
  width: 82px;
}

.itemTitleLong {
  color: var(--color-base-dark-sub);
}

.item {
  text-align: left;
  width: 50%;
}

.noItem {
  color: var(--color-gray-30);
}

.dividerWrapper {
  background-color: var(--color-light);
  padding-left: var(--spacing-s);
}

.lifelogHelpWrapper {
  text-align: right;
  padding-top: var(--spacing-xxs);
}

.lifelogHelpLink {
  color: var(--color-primary-100);
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);
}

.sectionList {
  background-color: var(--color-surface);
  padding: 18px 8px;
  overflow-x: auto;
  white-space: nowrap;

  /* 横スクロールバーを非表示 */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sectionList::-webkit-scrollbar {
  display: none;
}

.sectionListItem {
  appearance: none;
  background-color: transparent;
  border: none;
  color: var(--color-base-dark);
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  margin: 0 8px;
  padding: 8px 0;
}

.sectionListItemChecked {
  background-color: var(--color-base-dark);
  border-radius: 14px;
  color: var(--color-light);
  padding: 8px 16px;
}

.toggleSwitchText {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW3);
  padding-right: var(--spacing-xxs);
  color: var(--color-base-dark-sub);
}
