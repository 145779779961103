.popOverContainer {
  border-radius: var(--spacing-xxs);
  background: var(--color-light);
  padding: var(--spacing-s);
}

.popOverIcon {
  content: url('../../images/icon-notice.svg');
  width: 55px;
  height: 55px;
}

.popOverDescription {
  color: #2e2c37;
  font-size: 14px;
  margin-left: 12px;
}

.popOverContainer:before {
  content: '';
  position: absolute;
  top: -22px;
  left: 50%;
  margin-left: -12px;
  border: 12px solid transparent;
  border-bottom: 22px solid var(--color-light);
}

.popOverButtonWrapper {
  text-align: right;
}

.popOverButton {
  background: transparent;
  border: none;
  color: var(--color-primary-100);
  font-size: 14px;
  margin: 6px;
  outline: none;
  padding: 0;
}
