.sectionWrapper {
  margin: var(--spacing-s);
  width: 100%;
}

.sectionTitle {
  display: flex;
  height: 16px;
  padding-top: 8px;
}

.sectionTitleItem:nth-child(1) {
  width: 50%;
  font-family: var(--font-JosefinSans);
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.sectionTitleItem:nth-child(2) {
  width: 50%;
  text-align: end;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.linkText {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}

.availablePointSection {
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d8d8d8;
}

.pointIconWrapper {
  position: relative;
  margin-top: -4px;
  margin-right: 6px;
  width: 20px;
  height: 20px;
}

.availablePointSectionItem:nth-child(1) {
  display: flex;
}

.availablePointSectionItem:nth-child(2) {
  padding-top: 14px;
  text-align: end;
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  color: var(--color-primary-100);
}

@media screen and (max-width: 320px) {
  .availablePointSectionItem:nth-child(2) {
    font-size: 10px;
  }
}

.availablePointNumber {
  font-family: var(--font-JosefinSans);
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

@media screen and (max-width: 320px) {
  .availablePointNumber {
    font-size: 30px;
  }
}

.availablePointUnit {
  padding: 6px 0 0 4px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 320px) {
  .availablePointUnit {
    font-size: 11px;
  }
}

.expirePointSection {
  display: flex;
  justify-content: flex-start;
  padding-top: 6px;
}

.expirePointSectionItem {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 320px) {
  .expirePointSectionItem {
    font-size: 11px;
  }
}

.expirePointSectionItem:nth-child(1) {
  width: 60%;
}

.expirePointSectionItem:nth-child(2) {
  width: 40%;
  text-align: end;
}
