.container {
  background-color: var(--color-background);
  padding: var(--spacing-s);
  min-height: 100%;
}

.sectionWrapper {
  background-color: var(--color-light);
  border-radius: 16px;
  color: var(--color-base-dark);
  padding: var(--spacing-s);
  align-items: center;
}

.containerWithoutPadding {
  background-color: var(--color-background);
}
