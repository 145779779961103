.categoryScoresWrapper {
  padding-left: var(--spacing-xs);
  width: 45%;
}

.categoryScoreWrapper {
  padding: 5px 0;
  position: relative;
}

.categoryScoreImage {
  width: 24px;
}

.categoryScoreValueWrapper {
  color: #153f3b;
  font-family: var(--font-JosefinSans);
  font-size: 12px;
  text-align: left;
  padding: 0 6px;
  width: 50%;
}

@media screen and (max-width: 320px) {
  .categoryScoreValueWrapper {
    font-size: 10px;
  }
}

.categoryScoreValue {
  font-size: 22px;
  font-weight: 300;
}

.categoryScoreRankWrapper {
  font-size: 30px;
  font-weight: 300;
  font-family: var(--font-JosefinSans);
  position: relative;
  padding-top: 6px;
  padding-right: 18px;
}

.categoryScoreTrendWrapper {
  top: -16px;
  right: 0;
  text-align: center;
  position: absolute;
}
