.sectionTitleWrapper {
  display: flex;
  justify-content: space-between;
}

.sectionTitle {
  font-size: 18px;
  font-family: var(--font-HiraginoSansW6);
}

.helpButton {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  border-radius: 12px;
  appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-image: url('./circle-question.svg');
}
