.container {
  padding: 0 var(--spacing-s);
}

.message {
  padding-top: var(--spacing-m);
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  color: var(--color-base-dark);
}

.button {
  margin-top: 160px;
}
