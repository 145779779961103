.noticeArea {
  padding: var(--spacing-l) var(--spacing-s);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.notice {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
  white-space: pre-wrap;
}

.notice .child {
  margin-left: var(--spacing-xs);
}

.logoutButtonWrapper {
  margin: 80px var(--spacing-s) var(--spacing-xl) var(--spacing-s);
}
