.companyName {
  margin: var(--spacing-m) var(--spacing-s);
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--color-base-dark);
}

.fieldContainer {
  margin: var(--spacing-m) var(--spacing-s);
}

.fieldName {
  margin-bottom: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--color-base-dark);
}

.fieldBody {
  font-family: var(--spacing-xxs);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--color-base-dark);
  white-space: pre-wrap;
}

.dividerWrapper {
  background-color: var(--color-light);
  padding-left: var(--spacing-s);
}

.externalWeb {
  margin: var(--spacing-m) var(--spacing-s) var(--spacing-s);
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-primary-100);
}

.externalWebContainer {
  padding-bottom: var(--spacing-3xl);
}
