/* NOTE(smile-yoshryo): 基本的に横スクロール制御の範疇として幅はカルーセル側で制御される */
.bannerImage {
  height: 168px;
  border-radius: 16px;
}

.termDescriptionMask {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  border-radius: 0 0 16px 16px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 99%);
}

.termDescription {
  position: absolute;
  text-align: right;
  bottom: 16px;
  right: 16px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  color: var(--color-light);
}

.title {
  text-align: left;
  margin-top: 16px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 14px;
  color: var(--color-base-dark);
}

.scoreDescription {
  text-align: left;
  margin-top: 4px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
