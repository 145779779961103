.fontTermsTitle {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: #153f3b;
}

.fontTermsSubTitle {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #153f3b;
}

.fontTermsBody {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 0.03px;
  color: #153f3b;
}

.offerTerms {
  padding: var(--spacing-m) 0;
  background-color: var(--color-background);
}

.termsTitle {
  margin-bottom: var(--spacing-m);
  text-align: center;
  composes: fontTermsTitle;
}

.termsSubTitle {
  padding: 10px var(--spacing-xs);
  margin: var(--spacing-s) 0;
  composes: fontTermsSubTitle;
  background-color: #e7e0d8;
}

.termsBody {
  padding: 0 var(--spacing-s);
  composes: fontTermsBody;
  white-space: pre-wrap;
}
