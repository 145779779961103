.container {
  background-color: var(--color-background);
  padding: var(--spacing-s);
  min-height: 100%;
}

.sectionTitle {
  font-size: 18px;
  font-family: var(--font-HiraginoSansW6);
  line-height: 26px;
}

.sectionWrapper {
  background-color: var(--color-light);
  border-radius: 16px;
  color: var(--color-base-dark);
  padding: var(--spacing-s);
  align-items: center;
}

.pointSection {
  background-color: var(--color-light);
  height: 109px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
}

.pointBanner {
  height: 72px;
  background: #fff;
  border-radius: 8px;
  text-align: left;
}

.pointBanner img {
  border-radius: inherit;
  height: 100%;
  width: auto;
}

@media screen and (max-width: 380px) {
  .pointBanner {
    height: auto;
  }

  .pointBanner img {
    width: 100%;
  }
}

.pointBannerLink {
  text-decoration: none;
}

.dataSettingItem {
  background-color: var(--color-light);
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  position: relative;
}

.dataSettingLink {
  text-decoration: none;
}

.dataSettingIcon {
  width: 48px;
  height: 48px;
}

.dataSettingTitle {
  font-size: 14px;
  color: var(--color-base-dark);
}

.dataSettingItemSupplement {
  font-size: 11px;
  color: #4a8be7;
}

.dataSettingItemBadge {
  position: absolute;
  top: var(--spacing-xxs);
  right: var(--spacing-xxs);
  line-height: 0;
}

.transparentArea {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}
