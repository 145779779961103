.topMessage {
  font-size: 18px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.message {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.errorMessage {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .bottomContainer {
    display: none;
  }
}
