.body {
  padding-top: var(--spacing-4xl);
}

.textLarge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 44px;
  line-height: 1.38;
  font-family: var(--font-HiraginoSansW3);
}

.textSmall {
  text-align: center;
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 0.03px;
  font-family: var(--font-HiraginoSansW3);
}

.errorMessage {
  font-size: 10px;
  color: var(--color-danger);
}

.highlightText {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

@media screen and (max-height: 350px) {
  .bottomContainer {
    display: none;
  }
}
