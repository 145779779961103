.tutorialBody {
  background: black;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
}

.tutorialTarget {
  position: relative !important;
  z-index: 100001 !important;
}

.tutorialTarget::before,
.tutorialTarget::after {
  content: '';
  width: 100%;
  height: 16px;
  position: absolute;
  opacity: 0.8;
  background: white;
}

.tutorialTarget::before {
  top: -16px;
}

.tutorialTarget::after {
  bottom: -16px;
}

.tutorialPopOver {
  position: absolute;
  z-index: 100001 !important;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  animation: fadeIn 0.3s linear 1 forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
