.pointList {
  max-height: 516px;
  padding: var(--spacing-s);
  overflow-y: scroll;
}

.pointListItem {
  padding: 0px;
}

.monthSectionList {
  padding: 0px;
}

.monthSectionListItem {
  margin: var(--spacing-s) 0;
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: var(--color-base-dark-sub);
}
