.body {
  padding-top: 24px;
}

.textLarge {
  font-size: 16px;
  text-align: center;
  line-height: 1.38;
}

.textSmall {
  font-size: 12px;
  text-align: center;
}

.highlightText {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

.highlightTextLarge {
  font-size: 16px;
  text-align: center;
  color: var(--color-primary-100);
}

.errorMessage {
  font-size: 10px;
  color: var(--color-danger);
}
