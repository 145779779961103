.scoreCommentWrapper {
  background-color: #eff4f5;
  border-radius: 8px;
  padding: var(--spacing-s);
}

.scoreCommentDetailWrapper {
  color: #003543;
  font-family: var(--font-NotoSans);
  font-size: 12px;
}

.scoreCommentDetail {
  line-height: 1.83;
  padding-left: 16px;
  position: relative;
}

.scoreCommentDetail::before {
  content: url('../../../global/images/bubble-attention.svg');
  height: 14px;
  width: 12px;

  position: absolute;
  left: 0;
  top: 2px;
  margin: auto;
}

.noScoreCommentDetail {
  content: url('../../../global/images/bubble-attention.svg');
  height: 14px;
  width: 12px;
}

.bonusComment {
  color: var(--color-secondary-100);
}

.readMoreButtonWrapper {
  text-align: right;
}

.readMoreButton {
  border: none;
  background: transparent;
  outline: none;
  color: #003543;
  font-size: 12px;
}
