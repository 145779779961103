.bodyButton {
  width: 100%;
  border: solid 0 transparent;
  background-color: transparent;
  position: relative;
  line-height: 1.7;
  padding: 0;
}

.bodyButton .unreadBadge {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;
}

.offerContents {
  display: flex;
  align-items: top;
  justify-content: center;
}

.offerFeaturedImage {
  width: 76px;
  height: 76px;
}

.offerFeaturedImage img {
  border-radius: 4px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.offerType {
  margin-top: var(--spacing-3xs);
  text-align: center;
  width: 100%;
  height: 16px;
  font-family: var(--font-NotoSans);
  font-size: 10px;
  color: var(--color-light);
  background-color: #000;
  white-space: nowrap;
  overflow: hidden;
}

.offerMessage {
  margin-top: 10px;
  height: 44px;
  text-align: left;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
  /* はみ出た分は三点リーダー(...)で省略 */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: pre;
  /* 一部端末で三点リーダーが表示されない問題対応 */
  word-wrap: normal;
}

.offerCompanyName {
  text-align: left;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
  /* はみ出た分は三点リーダー(...)で省略 */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: pre;
  /* 一部端末で三点リーダーが表示されない問題対応 */
  word-wrap: normal;
}

.offerSummary {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: var(--spacing-xxs);
  width: 70%;
}

.offerMisc {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  line-height: 1;
}

.offerPoint {
  align-self: flex-start;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.offerPoint::before {
  content: '';
  display: inline-block;
  background-image: url('global/images/icon-point.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 6px;
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 320px) {
  .offerSummary {
    margin-left: var(--spacing-3xs);
  }

  .offerPoint {
    font-size: 10px;
  }

  .offerPoint::before {
    margin-right: 2px;
    width: 12px;
    height: 12px;
  }
}

.offerExpirationDate {
  align-self: flex-end;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

@media screen and (max-width: 320px) {
  .offerExpirationDate {
    font-size: 10px;
  }
}
