.body {
  padding-top: 40px;
}

.imageContainer {
  width: 100%;
  text-align: center;
}

.image {
  max-width: 344px;
  max-height: 258px;
  object-fit: contain;
}

.label {
  font-size: 16px;
  color: var(--color-base-dark);
  font-weight: bold;
  text-align: center;
}

.text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 0;
}
