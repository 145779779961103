.boardTitle {
  height: 30px;
  background-color: var(--color-primary-100);
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.42;
  color: var(--color-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progressGraph {
  height: 106px;
  background-color: var(--color-primary-20);
}

.progressNotification {
  padding: 12px 0 4px 0;
}

.progressNotificationString {
  margin: 0 auto;
  width: 220px;
  height: 20px;
  background-color: #ef6854;
  color: var(--color-light);
  font-family: var(--font-HiraginoSansW6);
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 6px;
}

.progressNotificationString:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -6px;
  left: 0;
  width: 0px;
  height: 0px;
  margin: auto;
  border-style: solid;
  border-color: #ef6854 transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}

.progressNotificationPointString {
  margin: 0 4px;
  color: #f8d323;
}

.questionTick {
  height: 24px;
  display: flex;
  justify-content: space-around;
}

.questionTickItemAchieved {
  font-family: var(--font-HiraginoSansW6);
  font-size: 14px;
  display: flex;
}

.questionTickItem {
  font-family: var(--font-HiraginoSansW6);
  font-size: 14px;
  display: flex;
  color: rgba(0, 0, 0, 0.5);
}

.questionTickItemNumber {
  padding: 2px;
  font-size: 18px;
  font-family: var(--font-JosefinSans);
}

.progressContainer {
  position: relative;
}

.progressIcon {
  height: 24px;
  display: flex;
  justify-content: space-around;
}

.progressIconItem {
  background-image: url('./icon-checked.svg');
  width: 20px;
  height: 20px;
  z-index: 2;
}

.progressIconItemUndone {
  background-image: url('./icon-unchecked.svg');
  width: 20px;
  height: 20px;
  z-index: 2;
}

.progressBarContainer {
  padding-left: 12.5%;
  padding-right: 12.5%;
}

.progressBar {
  height: 4px;
  width: 75%;
  position: absolute;
  top: 8px;
  z-index: 1;
}

.pointTick {
  height: 24px;
  display: flex;
  justify-content: space-around;
}

.pointTickItemAchieved {
  font-family: var(--font-HiraginoSansW5);
  font-size: 10px;
}

.pointTickItem {
  font-family: var(--font-HiraginoSansW5);
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.boardDescription {
  padding: var(--spacing-s);
  font-family: var(--font-HiraginoSansW4);
  font-size: 12px;
  line-height: 1.42;
  color: #121212;
}

.closeButton {
  position: absolute;
  right: 4px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-image: url('../../../global/images/icon-close.svg');
  outline: none;
}

.topBanner {
  height: 211px;
  background-image: url('../../../global/images/campaign-top-banner.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.topBannerBlur {
  position: absolute;
  width: 100%;
  height: 114px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 99%);
  bottom: 0px;
}

.topBannerString {
  position: absolute;
  bottom: 0px;
  padding: 16px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: normal;
  color: var(--color-light);
}
