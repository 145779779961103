.bodyButton {
  position: relative;
  width: 100%;
  border: none;
  background-color: transparent;
  line-height: 1.7;
  text-align: left;
  border-bottom: 1px solid #ecf0f7;
  padding: var(--spacing-s) 0;
}

.bodyButton .unreadBadge {
  line-height: 1;
  position: absolute;
  top: 8px;
  right: 8px;
}

.title {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.71;
  color: var(--color-base-dark);
  padding-right: 14px;
}

.summary {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.67;
  color: #979797;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  white-space: pre-wrap;
}
