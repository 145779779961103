.emptyStateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.emptyStateContentWrapper {
  margin: 0 auto;
  width: 70%;
}

.stateIcon {
  width: 88px;
  margin-bottom: 4px;
}

.stateTitle {
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
}

.stateDescription {
  color: var(--color-base-dark-sub);
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.7;
}
