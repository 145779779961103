.confirmPageWrapper {
  font-size: 14px;
  font-family: var(--font-HiraginoSansW3);
  padding: 60px var(--spacing-s) var(--spacing-m);
}

.sectionTitle {
  font-size: 16px;
  font-family: var(--font-HiraginoSansW6);
  margin-bottom: var(--spacing-m);
}

.inputItem {
  line-height: 1.7;
  padding: 2px 16px 22px;
}

.inputNoItem {
  color: var(--color-gray-30);
}

.confirmDescription {
  line-height: 1.7;
  text-align: center;
}
