@import 'global/ui/global.css';

.spinnerWrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-light);
}
