.closeButton {
  position: absolute;
  top: var(--spacing-m);
  right: var(--spacing-m);
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-image: url('../../images/icon-close.svg');
  outline: none;
}

.imageContainer {
  text-align: center;
  margin-bottom: var(--spacing-l);
}

.imageSmall {
  width: 128px;
  vertical-align: top;
}

.imageLarge {
  max-width: 312px;
  vertical-align: top;
}

.title {
  font-family: var(--font-NotoSans);
  font-size: 22px;
  font-weight: medium;
  color: #ef6854;
  text-align: center;
  white-space: pre-wrap;
}

.description {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  white-space: pre-wrap;
}

.left {
  text-align: left;
}