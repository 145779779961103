:root {
  --question-scroll-margin: 80px;
}

.sectionTitle {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: var(--color-base-dark-sub);
}

.categoryImageWrapper {
  text-align: center;
  margin: 32px 0 28px;
}

.categoryImage {
  width: 63px;
  height: 63px;
}

.questionWrapper {
  margin-bottom: 84px;
}

.questionTitleWrapper {
  text-align: center;
}

/* ios safariでscroll-margin-topと同様のことを実現するためのworkaround */
/* 参考: https://zenn.dev/catnose99/articles/75d3c69bf71bad */
.questionWrapper:before {
  display: block;
  content: '';
  margin-top: calc(var(--question-scroll-margin) * -1);
  height: var(--question-scroll-margin);
  visibility: hidden;
  pointer-events: none;
}

/* ios safariでscroll-margin-topと同様のことを実現するためのworkaround */
/* 参考: https://zenn.dev/catnose99/articles/75d3c69bf71bad */
.questionTitleWrapper:before {
  display: block;
  content: '';
  margin-top: calc(var(--question-scroll-margin) * -1);
  height: var(--question-scroll-margin);
  visibility: hidden;
  pointer-events: none;
}

.questionNumber {
  font-family: var(--font-JosefinSans);
  font-size: 28px;
  font-weight: 300;
  margin: 0;
  color: var(--color-base-dark);
}

.questionTitle {
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  text-align: center;
  margin: 0;
  margin-top: var(--spacing-3xs);
  color: var(--color-base-dark);
}

.questionSupplement {
  font-size: 12px;
  text-align: center;
  color: var(--color-base-dark-sub);
  white-space: pre-wrap;
}

.optionWrapper {
  display: flex;
  margin-top: 32px;
}

.optionWrapper.row {
  flex-direction: row;
  justify-content: center;
  column-gap: 10px;
}

.optionWrapper.column {
  flex-direction: column;
  align-items: flex-start;
  row-gap: var(--spacing-m);
  margin-left: var(--spacing-xxs);
}

.checkboxWrapper {
  display: flex;
}

.optionWrapper.row .checkboxWrapper {
  display: inline-block;
  width: 86px;
  flex-direction: column;
  text-align: center;
}

.optionWrapper.column .checkboxWrapper {
  flex-direction: row;
  text-align: left;
  align-items: center;
}

.optionLabel {
  font-family: var(--font-HiraginoSansW6);
}

.optionWrapper.row .optionLabelWrapper {
  margin-top: 6px;
}

.optionWrapper.row .optionLabel {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 11px;
}

.optionWrapper.column .optionLabelWrapper {
  margin-left: var(--spacing-s);
}

.optionWrapper.column .optionLabel {
  text-align: left;
  font-size: 12px;
}
