.descriptionPageWrapper {
  color: var(--color-base-dark);
  padding: var(--spacing-3xl) var(--spacing-s) 156px;
  text-align: center;
}

.descriptionPageTitle {
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  line-height: 1.5;
}

.descriptionPageDescription {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.7;
}
