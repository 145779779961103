.container {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-xl);
  min-height: 100vh;
  background-color: var(--color-background);
}

.titleCard {
  margin: 0 var(--spacing-s) var(--spacing-m) var(--spacing-s);
  background-color: transparent;
}

.noOfferWrapper {
  color: var(--color-base-dark-sub);
  font-size: 14px;
  font-family: var(--font-HiraginoSansW3);
  text-align: center;
  line-height: 1.7;
}

.noOfferImg {
  margin: auto;
  width: 88px;
}

.noOfferTitle {
  color: var(--color-base-dark);
  font-size: 16px;
  font-family: var(--font-HiraginoSansW6);
  margin: 16px 0;
}

.noOfferLinkWrapper {
  color: inherit;
  text-decoration: none;
}

.noOfferLink {
  color: var(--color-primary-100);
  text-decoration: underline;
  text-decoration-color: var(--color-primary-100);
}
