.container {
  background-color: var(--color-background);
  min-height: 100vh;
  padding: var(--spacing-s);
}

.categoryWrapper {
  background-color: var(--color-light);
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  color: var(--color-base-dark);
  font-family: var(--font-NotoSans);
  display: flex;
  padding: var(--spacing-s);
  margin: var(--spacing-s) 0;
  align-items: center;
}

.categoryImage {
  height: 48px;
  width: 48px;
}

.categoryDescriptionWrapper {
  margin-left: 20px;
}

.categoryTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.categoryAnswerStateWrapper {
  color: var(--color-base-dark-sub);
  font-size: 12px;
  font-family: var(--font-JosefinSans);
  font-weight: 300;
}

.unansweredQuestion {
  color: var(--color-base-dark);
  font-size: 40px;
  font-family: var(--font-JosefinSans);
  display: inline-block;
}

.totalQuestion {
  font-size: 20px;
  display: inline-block;
  margin: 0 2px 0 6.5px;
}

.categoryButtonWrapper {
  margin-left: auto;
}
