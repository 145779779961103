.input {
  border: none;
  outline: 0;
  color: var(--color-base-dark);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  letter-spacing: 0.06px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.inputPasswordLarge {
  font-size: 24px;
  line-height: 1.4;
  padding: 0 48px 0 20px;
}

input[type='password']::placeholder {
  color: rgba(18, 18, 18, 0.3);
}

.visibleButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;

  float: right;
  position: relative;
  margin-top: -25px;
  margin-right: 0;
  font-size: 12px;
}
