.container {
  padding: var(--spacing-s);
  min-height: 100%;
}

.registeredAt {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.67;
  color: var(--color-base-dark-sub);
}

.title {
  margin-top: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-base-dark);
}

.description {
  margin-top: var(--spacing-s);
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.71;
  white-space: pre-wrap;
  color: var(--color-base-dark);
}
