.button {
  width: 100%;
  height: 60px;
  padding: 0 var(--spacing-s);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;
}

.title {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.rightArrow {
  width: 24px;
  height: 24px;
}
