.pager {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  background-color: var(--color-light);
  border-radius: 50px;
}

.currentPage {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  background-color: var(--color-primary-100);
  border-radius: 50px;
}
