.container {
  padding: var(--spacing-s) 0;
  min-height: 100vh;
  background-color: var(--color-background);
}

.noticeArea {
  padding: 0 var(--spacing-s);
}

.contentsArea {
  margin: var(--spacing-xxs) var(--spacing-s) var(--spacing-xxl)
    var(--spacing-s);
  padding: var(--spacing-m) var(--spacing-s);
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  background-color: var(--color-light);
}

.ownedPointTitle {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.ownedPointRoot {
  display: flex;
  align-items: center;
}

.ownedPointRoot .point {
  margin-top: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  font-size: 32px;
  color: #000;
}

.ownedPointRoot .suffix {
  margin-top: var(--spacing-xxs);
}

.commonValueSuffix {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.exchangeGuide {
  margin-top: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.couponArea {
  margin-top: var(--spacing-s);
}

.sentenceDetailRoot {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.sentenceDetailRoot .couponTitle {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.valueAndQuantityPair {
  display: flex;
  align-items: flex-end;
}

.valueAndQuantityPair .exchangeCouponValue {
  margin-right: var(--spacing-3xs);
  width: 62px;
  height: 48px;
  font-family: var(--font-JosefinSans);
  text-align: center;
  font-size: 32px;
  border-radius: 6px;
  border: solid 1px #cbcbcb;
  color: #000;
}

.dividerWrapper {
  margin-top: var(--spacing-s);
}

.resultGuideTitle {
  margin-top: var(--spacing-m);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.resultDetailRoot {
  margin-top: var(--spacing-xxs);
  display: flex;
  flex-flow: column;
  gap: 16px 0;
}

.resultDetailTitle {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.resultNumValue {
  margin-right: var(--spacing-3xs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #f2554f;
}

.insufficientPoints {
  margin-top: var(--spacing-m);
  text-align: center;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #f2554f;
}

.exchangeButton {
  margin-top: var(--spacing-m);
}
