.activePageDot {
  display: 'inline-block';
  padding: var(--spacing-3xs);
  margin: 0 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: var(--color-primary-100);
  pointer-events: none;
}

.activePageDot:focus-visible {
  outline: none;
}

.deactivePageDot {
  display: 'inline-block';
  padding: var(--spacing-3xs);
  margin: 0 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: #d8d8d8;
}

.deactivePageDot:focus-visible {
  outline: none;
}
