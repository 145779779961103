@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.cdnfonts.com/css/sf-ui-text-2);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.TopPage_container__1Gi1g {
  background-color: var(--color-background);
  padding: var(--spacing-s);
  min-height: 100%;
}

.TopPage_sectionWrapper__bws3f {
  background-color: var(--color-light);
  border-radius: 16px;
  color: var(--color-base-dark);
  padding: var(--spacing-s);
  align-items: center;
}

.TopPage_containerWithoutPadding__3PV2O {
  background-color: var(--color-background);
}

.Dialog_closeButton__1Ktx8 {
  position: absolute;
  top: var(--spacing-m);
  right: var(--spacing-m);
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-image: url(/static/media/icon-close.35756345.svg);
  outline: none;
}

.Dialog_imageContainer__-28OG {
  text-align: center;
  margin-bottom: var(--spacing-l);
}

.Dialog_imageSmall__1JLMN {
  width: 128px;
  vertical-align: top;
}

.Dialog_imageLarge__34kB_ {
  max-width: 312px;
  vertical-align: top;
}

.Dialog_title__rNSgR {
  font-family: var(--font-NotoSans);
  font-size: 22px;
  font-weight: medium;
  color: #ef6854;
  text-align: center;
  white-space: pre-wrap;
}

.Dialog_description__2RI74 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  white-space: pre-wrap;
}

.Dialog_left__137hk {
  text-align: left;
}
.Button_button__35BOp {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  outline: none;
  position: relative;
}

.Button_buttonLarge__1XkYO {
  width: 100%;
  height: 48px;
  border-radius: 28px;
  font-family: var(--font-HiraginoSansW6);
  /* ボタンテキストは最長全角12文字を想定 */
  font-size: 18px;
}

.Button_buttonSmall__2SR0w {
  width: 100%;
  height: 32px;
  border-radius: 16px;
  font-family: var(--font-NotoSans);
  font-weight: 500;
  font-size: 12px;
  padding: 0 20px;
  white-space: nowrap;
}

.Button_buttonDefault__k_Q7j {
  color: var(--color-primary-100);
  background-color: var(--color-light);
  border: solid 1px var(--color-primary-100);
}

.Button_buttonPrimary__2zZfu {
  color: var(--color-light);
  background-color: var(--color-primary-100);
  border: 0;
}

.Button_buttonDestructive__3-cN- {
  color: #f2554f;
  background-color: var(--color-light);
  border: solid 1px #f2554f;
}

.Button_buttonDisabled__1vqFN {
  color: var(--color-light);
  background-color: var(--color-disabled);
}

.Button_iconExternal__1XC4k {
  padding-right: 16px;
}

.Button_iconExternal__1XC4k::after {
  content: '';
  background-image: url(/static/media/icon-external.0acf55e4.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  margin-left: 6px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

:root {
  height: 100%;
  /** colors */
  --color-base-dark: #101010;
  --color-base-dark-sub: rgba(16, 16, 16, 0.5);
  --color-base-disabled: rgba(16, 16, 16, 0.38);
  --color-light: #ffffff;
  --color-light-sub: rgba(255, 255, 255, 0.5);
  --color-surface: #fffaf5;
  --color-disabled: #e0e0e0;
  --color-primary-100: #009d85;
  --color-primary-50: rgba(0, 157, 133, 0.5);
  --color-primary-20: rgba(0, 157, 133, 0.2);
  --color-primary-10: rgba(0, 157, 133, 0.1);
  --color-secondary-100: #ff634c;
  --color-secondary-60: #f8a16b;
  --color-secondary-10: #fde0ce;
  --color-danger: #d5445c;
  --color-warning: #e9cf18;
  --color-success: #2dcc70;
  --color-info: #007ad5;
  --color-bgdanger: #f4d5d5;
  --color-bgwarning: #fbf5d1;
  --color-bgsuccess: #d5f5e2;
  --color-bginfo: #e5f1fa;
  --color-gray-100: #333333;
  --color-gray-60: rgba(51, 51, 51, 0.6);
  --color-gray-30: rgba(51, 51, 51, 0.3);
  --color-gray-10: rgba(51, 51, 51, 0.1);
  --color-graph-red: #f2554f;
  --color-state-up: #ef6854;
  --color-state-down: #5476ef;
  --color-state-unchanged: #5ac45d;

  --color-background: #efedec;

  /** spacings */
  --spacing-3xs: 4px;
  --spacing-xxs: 8px;
  --spacing-xs: 12px;
  --spacing-s: 16px;
  --spacing-m: 24px;
  --spacing-l: 32px;
  --spacing-xl: 40px;
  --spacing-xxl: 48px;
  --spacing-3xl: 64px;
  --spacing-4xl: 80px;

  /** font-family */
  --font-HiraginoSans: 'HiraginoSans', sans-serif;
  --font-HiraginoSansW2: 'HiraginoSans-W2', sans-serif;
  --font-HiraginoSansW3: 'HiraginoSans-W3', sans-serif;
  --font-HiraginoSansW4: 'HiraginoSans-W4', sans-serif;
  --font-HiraginoSansW5: 'HiraginoSans-W5', sans-serif;
  --font-HiraginoSansW6: 'HiraginoSans-W6', sans-serif;
  --font-NotoSans: 'Noto Sans JP', sans-serif;
  --font-JosefinSans: 'Josefin Sans', sans-serif;
  --font-SFUIText: 'SF UI Text', sans-serif;
}

body {
  font-family: 'HiraginoSans', sans-serif;
  font-family: var(--font-HiraginoSans);
  height: 100%;
  -webkit-touch-callout: none; /* iOSのwebkitの設定のため、必要に応じてAndroid用のプロパティも設定する(要調査) */
}

img {
  vertical-align: top; /* imgの下に余白ができるのを防ぐ */
  width: 100%;
}

p {
  font-family: 'HiraginoSans-W3', sans-serif;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
  padding: 0;
}

hr {
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  padding: 0;
}

.Loading_spinnerWrapper__2baiH {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-light);
}

.TopLifeScoreChart_pieChartWrapper__3yJKu {
  color: var(--color-gray-60);
  font-family: var(--font-NotoSans);
  height: 50%;
  width: 50%;
  max-width: 300px;
  min-width: 135px;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.TopLifeScoreChart_pieChartDetail__2R2vZ {
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.TopLifeScoreChart_pieChartTiele__1r0-b {
  margin-bottom: 10px;
}

.TopLifeScoreChart_scoreValueWrapper__23Llc {
  font-family: var(--font-JosefinSans);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 18px;
}

.TopLifeScoreChart_scoreValue__2VJaX {
  color: var(--color-base-dark);
  font-size: 40px;
  font-weight: 300;
  line-height: 0.5;
  margin-left: 8px;
  padding: 2px 0;
}

@media screen and (max-width: 320px) {
  .TopLifeScoreChart_scoreValue__2VJaX {
    font-size: 32px;
  }
}

.TopLifeScoreChart_scoreRankWrapper__1c1cN {
  display: inline-block;
  font-family: var(--font-JosefinSans);
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
  position: relative;
  width: 26px;
}

.TopLifeScoreChart_scoreTrendWrapper__O2qal {
  position: absolute;
  top: -16px;
  right: -16px;
}

.TopLifeScoreChart_lastUpdatedAt__2F4-n {
  font-family: var(--font-SFUIText);
  font-size: 11px;
  font-weight: 500;
}

.ScoreTrend_scoreTrend__2lBqT {
  display: table-cell;
  height: 26px;
  font-size: 10px;
  font-family: var(--font-JosefinSans);
  position: relative;
  width: 20px;
}

.ScoreTrend_scoreTrend__2lBqT::after {
  border-radius: 100%;
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
}

.ScoreTrend_scoreTrend__2lBqT.ScoreTrend_up__1E1gQ {
  color: var(--color-state-up);
}

.ScoreTrend_scoreTrend__2lBqT.ScoreTrend_down__2Pfsx {
  color: var(--color-state-down);
}

.ScoreTrend_scoreTrend__2lBqT.ScoreTrend_unchanged__bRjUa {
  color: var(--color-state-unchanged);
}

.ScoreTrend_scoreTrend__2lBqT.ScoreTrend_up__1E1gQ::after {
  content: url(/static/media/arrow-double-up.7822f43b.svg);
  background-color: var(--color-state-up);
}

.ScoreTrend_scoreTrend__2lBqT.ScoreTrend_down__2Pfsx::after {
  content: url(/static/media/arrow-double-down.4f68d6d0.svg);
  background-color: var(--color-state-down);
}

.ScoreTrend_scoreTrend__2lBqT.ScoreTrend_unchanged__bRjUa::after {
  content: url(/static/media/arrow-double-unchanged.667348e3.svg);
  background-color: var(--color-state-unchanged);
}

.TopLifeScoreComment_scoreCommentWrapper__30Fg0 {
  background-color: #eff4f5;
  border-radius: 8px;
  padding: var(--spacing-s);
}

.TopLifeScoreComment_scoreCommentDetailWrapper__1nCYs {
  color: #003543;
  font-family: var(--font-NotoSans);
  font-size: 12px;
}

.TopLifeScoreComment_scoreCommentDetail__mZvmT {
  line-height: 1.83;
  padding-left: 16px;
  position: relative;
}

.TopLifeScoreComment_scoreCommentDetail__mZvmT::before {
  content: url(/static/media/bubble-attention.58694de7.svg);
  height: 14px;
  width: 12px;

  position: absolute;
  left: 0;
  top: 2px;
  margin: auto;
}

.TopLifeScoreComment_noScoreCommentDetail__FMmxC {
  content: url(/static/media/bubble-attention.58694de7.svg);
  height: 14px;
  width: 12px;
}

.TopLifeScoreComment_bonusComment__3vNzM {
  color: var(--color-secondary-100);
}

.TopLifeScoreComment_readMoreButtonWrapper__2026i {
  text-align: right;
}

.TopLifeScoreComment_readMoreButton__3buQI {
  border: none;
  background: transparent;
  outline: none;
  color: #003543;
  font-size: 12px;
}

.TopCategoryLifeScores_categoryScoresWrapper__DIJVn {
  padding-left: var(--spacing-xs);
  width: 45%;
}

.TopCategoryLifeScores_categoryScoreWrapper__CCL40 {
  padding: 5px 0;
  position: relative;
}

.TopCategoryLifeScores_categoryScoreImage__3cmz2 {
  width: 24px;
}

.TopCategoryLifeScores_categoryScoreValueWrapper__3Temh {
  color: #153f3b;
  font-family: var(--font-JosefinSans);
  font-size: 12px;
  text-align: left;
  padding: 0 6px;
  width: 50%;
}

@media screen and (max-width: 320px) {
  .TopCategoryLifeScores_categoryScoreValueWrapper__3Temh {
    font-size: 10px;
  }
}

.TopCategoryLifeScores_categoryScoreValue__2OqjH {
  font-size: 22px;
  font-weight: 300;
}

.TopCategoryLifeScores_categoryScoreRankWrapper__mJaB0 {
  font-size: 30px;
  font-weight: 300;
  font-family: var(--font-JosefinSans);
  position: relative;
  padding-top: 6px;
  padding-right: 18px;
}

.TopCategoryLifeScores_categoryScoreTrendWrapper__ngfVg {
  top: -16px;
  right: 0;
  text-align: center;
  position: absolute;
}

.TopLifeScore_sectionTitleWrapper__JvrCO {
  display: flex;
  justify-content: space-between;
}

.TopLifeScore_sectionTitle__hcP6U {
  font-size: 18px;
  font-family: var(--font-HiraginoSansW6);
}

.TopLifeScore_helpButton__1xTPU {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  border-radius: 12px;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-image: url(/static/media/circle-question.42a750ce.svg);
}

.TopDailyScores_sectionTitle__kXO1U {
  font-size: 18px;
  font-family: var(--font-HiraginoSansW6);
}

.TopDailyScores_lineChartPeriodWapper__1IxWh {
  display: flex;
  justify-content: space-between;
}

.TopDailyScores_lineChartPeriod__w5Qbp {
  color: #738c89;
  font-family: var(--font-JosefinSans);
  font-size: 14px;
}

.TopDailyScores_lineChartPeriodSeparation__2ybRX {
  font-family: var(--font-HiraginoSansW6);
  padding: 0 4px;
}

.TopDailyScores_lineChartPeriodBtnWrapper__1te7K {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  width: 8px;
}

.TopDailyScores_lineChartPeriodBtnActive__FKKW2 {
  content: url(/static/media/arrow-active.4060920f.svg);
  width: 100%;
}

.TopDailyScores_lineChartPeriodBtnInActive__1vJhF {
  content: url(/static/media/arrow-inactive.6e1ba18a.svg);
  width: 100%;
}

.TopDailyScores_lineChartPeriodBtnNext__14-vJ {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.OfferListItem_bodyButton__t2dG- {
  width: 100%;
  border: solid 0 transparent;
  background-color: transparent;
  position: relative;
  line-height: 1.7;
  padding: 0;
}

.OfferListItem_bodyButton__t2dG- .OfferListItem_unreadBadge__1UaOF {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;
}

.OfferListItem_offerContents__1NF4x {
  display: flex;
  align-items: top;
  justify-content: center;
}

.OfferListItem_offerFeaturedImage__3gHVd {
  width: 76px;
  height: 76px;
}

.OfferListItem_offerFeaturedImage__3gHVd img {
  border-radius: 4px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.OfferListItem_offerType__twWgf {
  margin-top: var(--spacing-3xs);
  text-align: center;
  width: 100%;
  height: 16px;
  font-family: var(--font-NotoSans);
  font-size: 10px;
  color: var(--color-light);
  background-color: #000;
  white-space: nowrap;
  overflow: hidden;
}

.OfferListItem_offerMessage__1NHtA {
  margin-top: 10px;
  height: 44px;
  text-align: left;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
  /* はみ出た分は三点リーダー(...)で省略 */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: pre;
  /* 一部端末で三点リーダーが表示されない問題対応 */
  word-wrap: normal;
}

.OfferListItem_offerCompanyName__BwSiq {
  text-align: left;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
  /* はみ出た分は三点リーダー(...)で省略 */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: pre;
  /* 一部端末で三点リーダーが表示されない問題対応 */
  word-wrap: normal;
}

.OfferListItem_offerSummary__1gULR {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-left: var(--spacing-xxs);
  width: 70%;
}

.OfferListItem_offerMisc__34sSu {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  line-height: 1;
}

.OfferListItem_offerPoint__3Mndm {
  align-self: flex-start;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.OfferListItem_offerPoint__3Mndm::before {
  content: '';
  display: inline-block;
  background-image: url(/static/media/icon-point.f9603af3.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 6px;
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 320px) {
  .OfferListItem_offerSummary__1gULR {
    margin-left: var(--spacing-3xs);
  }

  .OfferListItem_offerPoint__3Mndm {
    font-size: 10px;
  }

  .OfferListItem_offerPoint__3Mndm::before {
    margin-right: 2px;
    width: 12px;
    height: 12px;
  }
}

.OfferListItem_offerExpirationDate__vvUwW {
  align-self: flex-end;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

@media screen and (max-width: 320px) {
  .OfferListItem_offerExpirationDate__vvUwW {
    font-size: 10px;
  }
}

.Badge_unreadBadge__1IDov {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fb3737;
}

.TopOffers_sectionTitle__1L1kx {
  font-size: 18px;
  font-family: var(--font-HiraginoSansW6);
}

.SummaryBoard_boardTitle__yT-KJ {
  height: 30px;
  background-color: var(--color-primary-100);
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.42;
  color: var(--color-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SummaryBoard_progressGraph__22xwG {
  height: 106px;
  background-color: var(--color-primary-20);
}

.SummaryBoard_progressNotification__1HQtQ {
  padding: 12px 0 4px 0;
}

.SummaryBoard_progressNotificationString__3Lafo {
  margin: 0 auto;
  width: 220px;
  height: 20px;
  background-color: #ef6854;
  color: var(--color-light);
  font-family: var(--font-HiraginoSansW6);
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 6px;
}

.SummaryBoard_progressNotificationString__3Lafo:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -6px;
  left: 0;
  width: 0px;
  height: 0px;
  margin: auto;
  border-style: solid;
  border-color: #ef6854 transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}

.SummaryBoard_progressNotificationPointString__26msp {
  margin: 0 4px;
  color: #f8d323;
}

.SummaryBoard_questionTick__Qpqkl {
  height: 24px;
  display: flex;
  justify-content: space-around;
}

.SummaryBoard_questionTickItemAchieved__Pu9zj {
  font-family: var(--font-HiraginoSansW6);
  font-size: 14px;
  display: flex;
}

.SummaryBoard_questionTickItem__2C-By {
  font-family: var(--font-HiraginoSansW6);
  font-size: 14px;
  display: flex;
  color: rgba(0, 0, 0, 0.5);
}

.SummaryBoard_questionTickItemNumber__ZiQUB {
  padding: 2px;
  font-size: 18px;
  font-family: var(--font-JosefinSans);
}

.SummaryBoard_progressContainer__3gDD7 {
  position: relative;
}

.SummaryBoard_progressIcon__3NJ5s {
  height: 24px;
  display: flex;
  justify-content: space-around;
}

.SummaryBoard_progressIconItem___C4fr {
  background-image: url(/static/media/icon-checked.eafcb5e4.svg);
  width: 20px;
  height: 20px;
  z-index: 2;
}

.SummaryBoard_progressIconItemUndone__BkqD5 {
  background-image: url(/static/media/icon-unchecked.652a12ad.svg);
  width: 20px;
  height: 20px;
  z-index: 2;
}

.SummaryBoard_progressBarContainer__ytDt4 {
  padding-left: 12.5%;
  padding-right: 12.5%;
}

.SummaryBoard_progressBar__1XVur {
  height: 4px;
  width: 75%;
  position: absolute;
  top: 8px;
  z-index: 1;
}

.SummaryBoard_pointTick__1SJA0 {
  height: 24px;
  display: flex;
  justify-content: space-around;
}

.SummaryBoard_pointTickItemAchieved__meaDE {
  font-family: var(--font-HiraginoSansW5);
  font-size: 10px;
}

.SummaryBoard_pointTickItem__RzZZw {
  font-family: var(--font-HiraginoSansW5);
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.SummaryBoard_boardDescription__399LJ {
  padding: var(--spacing-s);
  font-family: var(--font-HiraginoSansW4);
  font-size: 12px;
  line-height: 1.42;
  color: #121212;
}

.SummaryBoard_closeButton__2S3rC {
  position: absolute;
  right: 4px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-image: url(/static/media/icon-close.35756345.svg);
  outline: none;
}

.SummaryBoard_topBanner__snt7K {
  height: 211px;
  background-image: url(/static/media/campaign-top-banner.c51db961.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.SummaryBoard_topBannerBlur__1xDQ6 {
  position: absolute;
  width: 100%;
  height: 114px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 99%);
  bottom: 0px;
}

.SummaryBoard_topBannerString__cqObY {
  position: absolute;
  bottom: 0px;
  padding: 16px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: normal;
  color: var(--color-light);
}

.CampaignBanner_image__yKbI8 {
  width: 100%;
  height: auto;
}

.EmptyStateContainer_emptyStateContainer__1JTTH {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.EmptyStateContainer_emptyStateContentWrapper__31pB7 {
  margin: 0 auto;
  width: 70%;
}

.EmptyStateContainer_stateIcon__2uMSO {
  width: 88px;
  margin-bottom: 4px;
}

.EmptyStateContainer_stateTitle__Qcok6 {
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
}

.EmptyStateContainer_stateDescription__FNtyu {
  color: var(--color-base-dark-sub);
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.7;
}

.QuestionSection_questionContainer__sG8VC {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  border-radius: 8px;
  color: var(--color-base-dark);
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-s);
}

.QuestionSection_questionIcon__2l5MB {
  background-color: var(--color-light-sub);
  margin: auto 0;
  width: 48px;
}

@media screen and (max-width: 320px) {
  .QuestionSection_questionIcon__2l5MB {
    height: 34px;
    width: 34px;
  }
}

.QuestionSection_questionDetail__1Bnl9 {
  padding-left: 10px;
  padding-right: 10px;
  color: var(--color-base-dark);
  min-width: 40%;
}

.QuestionSection_questionName__1UHC3 {
  font-family: var(--font-HiraginoSansW6);
  font-size: 14px;
  line-height: 1.71;
  white-space: pre-wrap;
}

.QuestionSection_questionProgress__zdcZt {
  font-family: var(--font-JosefinSans);
  font-size: 40px;
  font-weight: 300;
  height: 30px;
  display: flex;
  align-items: baseline;
}

.QuestionSection_questionTotal__2mfGc {
  color: var(--color-base-dark-sub);
  font-size: 20px;
  padding-top: 15px;
  padding-left: 2px;
}

.QuestionSection_questionTotalUnit__2ylb4 {
  color: var(--color-base-dark-sub);
  padding-left: 2px;
  padding-top: 15px;
  font-size: 12px;
  font-family: var(--font-HiraginoSansW2);
}

.QuestionSection_questionButton__3Y9ml {
  margin-left: auto;
}

.QuestionSection_questionDisableButton__1hGSI {
  margin-top: 4px;
}

.QuestionSection_questionEnableButton__bK_8N {
  margin-top: 4px;
}

.QuestionSection_questionButtonPointLabel__37OcZ {
  margin-top: 4px;
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);
  text-align: center;
}

@media screen and (max-width: 320px) {
  .QuestionSection_questionButtonPointLabel__37OcZ {
    font-size: 10px;
  }
}

.QuestionnaireTopPage_container__9708J {
  background-color: var(--color-background);
  min-height: 100vh;
  padding: var(--spacing-s);
}

.QuestionnaireTopPage_categoryWrapper__13tcI {
  background-color: var(--color-light);
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  color: var(--color-base-dark);
  font-family: var(--font-NotoSans);
  display: flex;
  padding: var(--spacing-s);
  margin: var(--spacing-s) 0;
  align-items: center;
}

.QuestionnaireTopPage_categoryImage__3FLJd {
  height: 48px;
  width: 48px;
}

.QuestionnaireTopPage_categoryDescriptionWrapper__10sNE {
  margin-left: 20px;
}

.QuestionnaireTopPage_categoryTitle__35EUF {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.QuestionnaireTopPage_categoryAnswerStateWrapper__1WKEw {
  color: var(--color-base-dark-sub);
  font-size: 12px;
  font-family: var(--font-JosefinSans);
  font-weight: 300;
}

.QuestionnaireTopPage_unansweredQuestion__2RCaA {
  color: var(--color-base-dark);
  font-size: 40px;
  font-family: var(--font-JosefinSans);
  display: inline-block;
}

.QuestionnaireTopPage_totalQuestion__kR_Mi {
  font-size: 20px;
  display: inline-block;
  margin: 0 2px 0 6.5px;
}

.QuestionnaireTopPage_categoryButtonWrapper__2juhb {
  margin-left: auto;
}

.Checkbox_checkbox__25cE3 {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.Checkbox_checkbox__25cE3.Checkbox_checked__1wiCD {
  background-image: url(/static/media/icon-checked.88479b41.svg);
}

.Checkbox_checkbox__25cE3.Checkbox_unchecked__2QCSt {
  background-image: url(/static/media/icon-unchecked.5e97875f.svg);
}

:root {
  --question-scroll-margin: 80px;
}

.QuestionnaireQuestionList_sectionTitle__2LzFi {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: var(--color-base-dark-sub);
}

.QuestionnaireQuestionList_categoryImageWrapper__1MWv6 {
  text-align: center;
  margin: 32px 0 28px;
}

.QuestionnaireQuestionList_categoryImage__1qZpM {
  width: 63px;
  height: 63px;
}

.QuestionnaireQuestionList_questionWrapper__B2mrA {
  margin-bottom: 84px;
}

.QuestionnaireQuestionList_questionTitleWrapper__29HUt {
  text-align: center;
}

/* ios safariでscroll-margin-topと同様のことを実現するためのworkaround */
/* 参考: https://zenn.dev/catnose99/articles/75d3c69bf71bad */
.QuestionnaireQuestionList_questionWrapper__B2mrA:before {
  display: block;
  content: '';
  margin-top: calc(80px * -1);
  margin-top: calc(var(--question-scroll-margin) * -1);
  height: 80px;
  height: var(--question-scroll-margin);
  visibility: hidden;
  pointer-events: none;
}

/* ios safariでscroll-margin-topと同様のことを実現するためのworkaround */
/* 参考: https://zenn.dev/catnose99/articles/75d3c69bf71bad */
.QuestionnaireQuestionList_questionTitleWrapper__29HUt:before {
  display: block;
  content: '';
  margin-top: calc(80px * -1);
  margin-top: calc(var(--question-scroll-margin) * -1);
  height: 80px;
  height: var(--question-scroll-margin);
  visibility: hidden;
  pointer-events: none;
}

.QuestionnaireQuestionList_questionNumber__2rYJ3 {
  font-family: var(--font-JosefinSans);
  font-size: 28px;
  font-weight: 300;
  margin: 0;
  color: var(--color-base-dark);
}

.QuestionnaireQuestionList_questionTitle__2hrGz {
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  text-align: center;
  margin: 0;
  margin-top: var(--spacing-3xs);
  color: var(--color-base-dark);
}

.QuestionnaireQuestionList_questionSupplement__2fz6z {
  font-size: 12px;
  text-align: center;
  color: var(--color-base-dark-sub);
  white-space: pre-wrap;
}

.QuestionnaireQuestionList_optionWrapper__3EQ44 {
  display: flex;
  margin-top: 32px;
}

.QuestionnaireQuestionList_optionWrapper__3EQ44.QuestionnaireQuestionList_row__RSlpo {
  flex-direction: row;
  justify-content: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.QuestionnaireQuestionList_optionWrapper__3EQ44.QuestionnaireQuestionList_column__19wze {
  flex-direction: column;
  align-items: flex-start;
  grid-row-gap: var(--spacing-m);
  row-gap: var(--spacing-m);
  margin-left: var(--spacing-xxs);
}

.QuestionnaireQuestionList_checkboxWrapper__ClRWL {
  display: flex;
}

.QuestionnaireQuestionList_optionWrapper__3EQ44.QuestionnaireQuestionList_row__RSlpo .QuestionnaireQuestionList_checkboxWrapper__ClRWL {
  display: inline-block;
  width: 86px;
  flex-direction: column;
  text-align: center;
}

.QuestionnaireQuestionList_optionWrapper__3EQ44.QuestionnaireQuestionList_column__19wze .QuestionnaireQuestionList_checkboxWrapper__ClRWL {
  flex-direction: row;
  text-align: left;
  align-items: center;
}

.QuestionnaireQuestionList_optionLabel__2zLKn {
  font-family: var(--font-HiraginoSansW6);
}

.QuestionnaireQuestionList_optionWrapper__3EQ44.QuestionnaireQuestionList_row__RSlpo .QuestionnaireQuestionList_optionLabelWrapper__2PP62 {
  margin-top: 6px;
}

.QuestionnaireQuestionList_optionWrapper__3EQ44.QuestionnaireQuestionList_row__RSlpo .QuestionnaireQuestionList_optionLabel__2zLKn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 11px;
}

.QuestionnaireQuestionList_optionWrapper__3EQ44.QuestionnaireQuestionList_column__19wze .QuestionnaireQuestionList_optionLabelWrapper__2PP62 {
  margin-left: var(--spacing-s);
}

.QuestionnaireQuestionList_optionWrapper__3EQ44.QuestionnaireQuestionList_column__19wze .QuestionnaireQuestionList_optionLabel__2zLKn {
  text-align: left;
  font-size: 12px;
}

.QuestionnaireDetailPage_container__2wKbv {
  padding: var(--spacing-m);
  scroll-margin-top: 48px;
}

.QuestionnaireDetailPage_buttonArea__3EfPy {
  display: flex;
  flex-direction: column;
  grid-row-gap: 32px;
  row-gap: 32px;
  border-top: 1px solid rgba(236, 240, 247, 1);
  padding-top: 49px;
  padding-bottom: 58px;
}

.OfferList_sectionPanel__1q-2W {
  display: flex;
  flex-direction: column;
  background-color: var(--color-light);
  color: var(--color-base-dark);
}

.OfferTopPage_container__2Wt-C {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-xl);
  min-height: 100vh;
  background-color: var(--color-background);
}

.OfferTopPage_titleCard__3HQ4P {
  margin: 0 var(--spacing-s) var(--spacing-m) var(--spacing-s);
  background-color: transparent;
}

.OfferTopPage_noOfferWrapper__d0zY2 {
  color: var(--color-base-dark-sub);
  font-size: 14px;
  font-family: var(--font-HiraginoSansW3);
  text-align: center;
  line-height: 1.7;
}

.OfferTopPage_noOfferImg__1gN8l {
  margin: auto;
  width: 88px;
}

.OfferTopPage_noOfferTitle__RkNel {
  color: var(--color-base-dark);
  font-size: 16px;
  font-family: var(--font-HiraginoSansW6);
  margin: 16px 0;
}

.OfferTopPage_noOfferLinkWrapper__2cipl {
  color: inherit;
  text-decoration: none;
}

.OfferTopPage_noOfferLink__oeyi1 {
  color: var(--color-primary-100);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-primary-100);
          text-decoration-color: var(--color-primary-100);
}

.AnnouncementOfferDetailPage_container__1USdd {
  background-color: var(--color-background);
}

.AnnouncementCaution_fontTermsTitle__1ToOe {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: #153f3b;
}

.AnnouncementCaution_fontTermsSubTitle__1SpZZ {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #153f3b;
}

.AnnouncementCaution_fontTermsBody__EyOiW {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 0.03px;
  color: #153f3b;
}

.AnnouncementCaution_offerTerms__1L8fU {
  padding: var(--spacing-m) 0;
  background-color: var(--color-background);
}

.AnnouncementCaution_termsTitle__2XF5O {
  margin-bottom: var(--spacing-m);
  text-align: center;
}

.AnnouncementCaution_termsSubTitle__1vhRx {
  padding: 10px var(--spacing-xs);
  margin: var(--spacing-s) 0;
  background-color: #e7e0d8;
}

.AnnouncementCaution_termsBody__2lBU_ {
  padding: 0 var(--spacing-s);
  white-space: pre-wrap;
}

.DetailItem_fontOfferDetailTitle__2ANPE {
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  color: var(--color-base-dark);
}

.DetailItem_fontOfferDetailMessage__10wF_ {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
  white-space: pre-wrap;
}

.DetailItem_offerDetailTitle__1tBaY {
  margin-bottom: var(--spacing-s);
}

.DetailItem_offerDetailMessage__9f2G1 {
}

.PointNotice_fontPointReason__20s38 {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-gray-100);
}

.PointNotice_fontPointDetail__1qLXs {
  font-family: var(--font-NotoSans);
  font-size: 14px;
  color: #f36c18;
}

@media screen and (max-width: 375px) {
  .PointNotice_fontPointReason__20s38,
  .PointNotice_fontPointDetail__1qLXs {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .PointNotice_fontPointReason__20s38,
  .PointNotice_fontPointDetail__1qLXs {
    font-size: 11px;
  }
}

.PointNotice_pointSummary__23KDv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 72px;

  background-color: rgba(243, 100, 12, 0.1);
}

.PointNotice_pointReason__1nGek {
  padding-left: var(--spacing-m);
  position: relative;
}

.PointNotice_pointReason__1nGek::before {
  content: '';
  background-image: url(/static/media/icon-check-inverted.79ba0b39.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.PointNotice_pointDetail__2REDJ {
  padding-left: var(--spacing-m);
  position: relative;
}

.PointNotice_pointDetail__2REDJ::before {
  content: '';
  background-image: url(/static/media/icon-point.f9603af3.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.DetailIntro_fontOfferTitle__2jxYu {
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  color: var(--color-base-dark);
}

.DetailIntro_fontOfferMessage__G7NxG {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark);
}

.DetailIntro_fontOfferExpirationDate__TlO0w {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: rgba(18, 18, 18, 0.6);
}

.DetailIntro_offerTitle__1hPUD {
  margin-bottom: var(--spacing-3xs);
  white-space: pre-wrap;
}

.DetailIntro_offerMessage__1M_AQ {
  margin-bottom: var(--spacing-xxs);
}

.DetailIntro_offerExpirationDate__hSTY4 {
  text-align: right;
}

.QuestionnaireOfferDetailPage_container__2sP90 {
  background-color: var(--color-background);
}

.QuestionnaireCaution_fontTermsTitle__2oHNC {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: #153f3b;
}

.QuestionnaireCaution_fontTermsSubTitle__38HTy {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #153f3b;
}

.QuestionnaireCaution_fontTermsBody__361y9 {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 0.03px;
  color: #153f3b;
}

.QuestionnaireCaution_offerTerms__3gN9M {
  padding: var(--spacing-m) 0;
  background-color: var(--color-background);
}

.QuestionnaireCaution_termsTitle__3A9G5 {
  margin-bottom: var(--spacing-m);
  text-align: center;
}

.QuestionnaireCaution_termsSubTitle__Bx4Bx {
  padding: 10px var(--spacing-xs);
  margin: var(--spacing-s) 0;
  background-color: #e7e0d8;
}

.QuestionnaireCaution_termsBody__3ACL2 {
  padding: 0 var(--spacing-s);
  white-space: pre-wrap;
}

.PopOver_popOverContainer__6blo1 {
  border-radius: var(--spacing-xxs);
  background: var(--color-light);
  padding: var(--spacing-s);
}

.PopOver_popOverIcon__15t_d {
  content: url(/static/media/icon-notice.2f6a9f11.svg);
  width: 55px;
  height: 55px;
}

.PopOver_popOverDescription__3eWnp {
  color: #2e2c37;
  font-size: 14px;
  margin-left: 12px;
}

.PopOver_popOverContainer__6blo1:before {
  content: '';
  position: absolute;
  top: -22px;
  left: 50%;
  margin-left: -12px;
  border: 12px solid transparent;
  border-bottom: 22px solid var(--color-light);
}

.PopOver_popOverButtonWrapper__2dujq {
  text-align: right;
}

.PopOver_popOverButton__1Uu_s {
  background: transparent;
  border: none;
  color: var(--color-primary-100);
  font-size: 14px;
  margin: 6px;
  outline: none;
  padding: 0;
}

.CoarchMark_tutorialBody__2Aj-M {
  background: black;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
}

.CoarchMark_tutorialTarget__1W7eL {
  position: relative !important;
  z-index: 100001 !important;
}

.CoarchMark_tutorialTarget__1W7eL::before,
.CoarchMark_tutorialTarget__1W7eL::after {
  content: '';
  width: 100%;
  height: 16px;
  position: absolute;
  opacity: 0.8;
  background: white;
}

.CoarchMark_tutorialTarget__1W7eL::before {
  top: -16px;
}

.CoarchMark_tutorialTarget__1W7eL::after {
  bottom: -16px;
}

.CoarchMark_tutorialPopOver__aAp1c {
  position: absolute;
  z-index: 100001 !important;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-animation: CoarchMark_fadeIn__QhCbX 0.3s linear 1 forwards;
          animation: CoarchMark_fadeIn__QhCbX 0.3s linear 1 forwards;
}

@-webkit-keyframes CoarchMark_fadeIn__QhCbX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes CoarchMark_fadeIn__QhCbX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.DialogUsedCarousel_activePageDot__1v6kr {
  display: 'inline-block';
  padding: var(--spacing-3xs);
  margin: 0 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: var(--color-primary-100);
  pointer-events: none;
}

.DialogUsedCarousel_activePageDot__1v6kr:focus-visible {
  outline: none;
}

.DialogUsedCarousel_deactivePageDot__3Sefn {
  display: 'inline-block';
  padding: var(--spacing-3xs);
  margin: 0 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: #d8d8d8;
}

.DialogUsedCarousel_deactivePageDot__3Sefn:focus-visible {
  outline: none;
}

.DialogInSlideShow_slide__P6I8Q {
  margin: var(--spacing-m) var(--spacing-s) var(--spacing-xl) var(--spacing-s);
  height: 380px;
}

.DialogInSlideShow_dialogContentsRoot__1n_OA {
  padding-top: var(--spacing-m);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DialogInSlideShow_imageContainer__18iUh {
  max-width: 312px;
  margin-bottom: var(--spacing-l);
}

.DialogInSlideShow_title___41m9 {
  font-family: var(--font-NotoSans);
  font-size: 22px;
  font-weight: medium;
  color: #ef6854;
  text-align: center;
}

@media screen and (max-height: 570px) {
  .DialogInSlideShow_slide__P6I8Q {
    height: 330px;
  }

  .DialogInSlideShow_dialogContentsRoot__1n_OA {
    padding-top: var(--spacing-m);
  }

  .DialogInSlideShow_imageContainer__18iUh {
    width: 270px;
    margin-bottom: var(--spacing-m);
  }

  .DialogInSlideShow_title___41m9 {
    font-size: 20px;
  }
}

.DialogInSlideShow_description__1ZT3s {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  white-space: pre-wrap;
}

.DialogInSlideShow_primaryButton__mGQEN {
  margin: var(--spacing-xxs) var(--spacing-s) var(--spacing-m) var(--spacing-s);
}

.MyDataTopPage_container__3EGEq {
  background-color: var(--color-background);
  padding: var(--spacing-s);
  min-height: 100%;
}

.MyDataTopPage_sectionTitle__278oU {
  font-size: 18px;
  font-family: var(--font-HiraginoSansW6);
  line-height: 26px;
}

.MyDataTopPage_sectionWrapper__CH4Hv {
  background-color: var(--color-light);
  border-radius: 16px;
  color: var(--color-base-dark);
  padding: var(--spacing-s);
  align-items: center;
}

.MyDataTopPage_pointSection__1x2re {
  background-color: var(--color-light);
  height: 109px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
}

.MyDataTopPage_pointBanner__1UPL8 {
  height: 72px;
  background: #fff;
  border-radius: 8px;
  text-align: left;
}

.MyDataTopPage_pointBanner__1UPL8 img {
  border-radius: inherit;
  height: 100%;
  width: auto;
}

@media screen and (max-width: 380px) {
  .MyDataTopPage_pointBanner__1UPL8 {
    height: auto;
  }

  .MyDataTopPage_pointBanner__1UPL8 img {
    width: 100%;
  }
}

.MyDataTopPage_pointBannerLink__2Uo93 {
  text-decoration: none;
}

.MyDataTopPage_dataSettingItem__1wwxv {
  background-color: var(--color-light);
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  position: relative;
}

.MyDataTopPage_dataSettingLink__3wQEB {
  text-decoration: none;
}

.MyDataTopPage_dataSettingIcon__1PWFm {
  width: 48px;
  height: 48px;
}

.MyDataTopPage_dataSettingTitle__195Ao {
  font-size: 14px;
  color: var(--color-base-dark);
}

.MyDataTopPage_dataSettingItemSupplement__1ikb8 {
  font-size: 11px;
  color: #4a8be7;
}

.MyDataTopPage_dataSettingItemBadge__fJwu- {
  position: absolute;
  top: var(--spacing-xxs);
  right: var(--spacing-xxs);
  line-height: 0;
}

.MyDataTopPage_transparentArea__ujnYx {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.PointIcon_parent__3RitP {
  position: relative;
  margin-top: 6px;
  margin-right: 6px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #fee455;
}

.PointIcon_child__7qVv3 {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #fed33c;
}

.PointIcon_string__39Ne9 {
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  position: absolute;
  font-size: 12px;
  color: #ffffff;
  font-weight: 900;
}

.FitstatsPoint_sectionWrapper__p0cB5 {
  margin: var(--spacing-s);
  width: 100%;
}

.FitstatsPoint_sectionTitle__33LIU {
  display: flex;
  height: 16px;
  padding-top: 8px;
}

.FitstatsPoint_sectionTitleItem__3CK5w:nth-child(1) {
  width: 50%;
  font-family: var(--font-JosefinSans);
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.FitstatsPoint_sectionTitleItem__3CK5w:nth-child(2) {
  width: 50%;
  text-align: end;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.FitstatsPoint_linkText__2qNBn {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}

.FitstatsPoint_availablePointSection__1_vdP {
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d8d8d8;
}

.FitstatsPoint_pointIconWrapper__1x4j_ {
  position: relative;
  margin-top: -4px;
  margin-right: 6px;
  width: 20px;
  height: 20px;
}

.FitstatsPoint_availablePointSectionItem__1sD4R:nth-child(1) {
  display: flex;
}

.FitstatsPoint_availablePointSectionItem__1sD4R:nth-child(2) {
  padding-top: 14px;
  text-align: end;
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  color: var(--color-primary-100);
}

@media screen and (max-width: 320px) {
  .FitstatsPoint_availablePointSectionItem__1sD4R:nth-child(2) {
    font-size: 10px;
  }
}

.FitstatsPoint_availablePointNumber__ltkS9 {
  font-family: var(--font-JosefinSans);
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

@media screen and (max-width: 320px) {
  .FitstatsPoint_availablePointNumber__ltkS9 {
    font-size: 30px;
  }
}

.FitstatsPoint_availablePointUnit__1QCJf {
  padding: 6px 0 0 4px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 320px) {
  .FitstatsPoint_availablePointUnit__1QCJf {
    font-size: 11px;
  }
}

.FitstatsPoint_expirePointSection__2NQGS {
  display: flex;
  justify-content: flex-start;
  padding-top: 6px;
}

.FitstatsPoint_expirePointSectionItem__3c6KW {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 320px) {
  .FitstatsPoint_expirePointSectionItem__3c6KW {
    font-size: 11px;
  }
}

.FitstatsPoint_expirePointSectionItem__3c6KW:nth-child(1) {
  width: 60%;
}

.FitstatsPoint_expirePointSectionItem__3c6KW:nth-child(2) {
  width: 40%;
  text-align: end;
}

.BottomContainer_bottomContainer__19n_j {
  position: fixed;
  z-index: 10;
  width: 100%;
}

/* これ以下に設定を下げる場合は、小さい端末でinputの入力欄がフッターに隠れる問題を考慮すること */
@media screen and (max-height: 340px) {
  .BottomContainer_bottomContainer__19n_j {
    display: none;
  }
}

.NoticeContainer_noticeContainer__3_DKC {
  background-color: var(--color-light);
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  color: var(--color-base-dark-sub);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  padding: 20px 46px 20px 16px;

  position: relative;
}

.NoticeContainer_showContainer__3eAfm {
  display: block;
}

.NoticeContainer_closeContainer__3rrmA {
  display: none;
}

.NoticeContainer_noticeIcon__3RzLH {
  content: url(/static/media/icon-notice.2f6a9f11.svg);
  width: 48px;
  height: 48px;
}

.NoticeContainer_closeBtn__3Gq9d {
  content: url(/static/media/icon-close.35756345.svg);
  border-radius: 100%;
  background-color: #000;
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  cursor: pointer;
}

.ToggleSwitch_switch__1Akfn {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 28px;
  background: white;
  border-radius: 40px;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ToggleSwitch_switch__1Akfn::before {
  content: '';
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 100%;
  transition: all 0.2s;
}

.ToggleSwitch_switch__1Akfn.ToggleSwitch_on__33uh- {
  background-color: var(--color-primary-100);
}

.ToggleSwitch_switch__1Akfn.ToggleSwitch_off__5bHrJ {
  background-color: #f1eff5;
}

.ToggleSwitch_switch__1Akfn.ToggleSwitch_on__33uh-::before {
  -webkit-transform: translate3d(32px, 0, 0);
          transform: translate3d(32px, 0, 0);
}

.ToggleSwitch_labelText__1cJyO {
  font-size: 10px;
  /* chrome等のブラウザでは10px以下のfont-sizeを指定しても10pxになってしまうため、transformで縮小させている */
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-transform-origin: center;
          transform-origin: center;
  font-family: var(--font-HiraginoSansW3);
}

.ToggleSwitch_on__33uh- .ToggleSwitch_labelText__1cJyO {
  text-align: left;
  padding-left: 4px;
  color: var(--color-light);
}

.ToggleSwitch_off__5bHrJ .ToggleSwitch_labelText__1cJyO {
  text-align: right;
  padding-right: 1 px;
  color: #859194;
}

.TopContainer_topContainer__3mXcV {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
}

.PersonalData_personalDataContainer__15inq {
  background-color: var(--color-background);
  padding: 60px 0 150px;
}

.PersonalData_selectButton__QjEp5 {
  background-image: url(/static/media/icon-public.53e659e4.svg);
  width: 60px;
  height: 28px;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  cursor: pointer;
}

.PersonalData_sectionWrapper__RxvN0 {
  padding: 12px var(--spacing-s);
}

.PersonalData_sectionWrapperHighlight__HgG-P {
  background-color: #d5e9e6;
}

.PersonalData_sectionTitle__2cna1 {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);

  /* アンカーリンクで遷移した際の位置を調整 */
  margin-top: -70px;
  padding-top: 70px;
}

.PersonalData_sectionTitleHighlight__1EGFS {
  color: #153f3b;
  font-family: var(--font-HiraginoSansW3);
}

.PersonalData_itemWrapper__1kMB3 {
  background-color: var(--color-light);
  font-family: var(--font-NotoSans);
  font-size: 14px;
  font-weight: 300;
  padding: 20px var(--spacing-s);
}

.PersonalData_itemTitle__3ezQx {
  color: var(--color-base-dark-sub);
  width: 82px;
}

.PersonalData_itemTitleLong__2fMyI {
  color: var(--color-base-dark-sub);
}

.PersonalData_item__vwhhH {
  text-align: left;
  width: 50%;
}

.PersonalData_noItem__TZO4u {
  color: var(--color-gray-30);
}

.PersonalData_dividerWrapper__2Y9-n {
  background-color: var(--color-light);
  padding-left: var(--spacing-s);
}

.PersonalData_lifelogHelpWrapper__2ZYD6 {
  text-align: right;
  padding-top: var(--spacing-xxs);
}

.PersonalData_lifelogHelpLink__5D446 {
  color: var(--color-primary-100);
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);
}

.PersonalData_sectionList__oe20p {
  background-color: var(--color-surface);
  padding: 18px 8px;
  overflow-x: auto;
  white-space: nowrap;

  /* 横スクロールバーを非表示 */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.PersonalData_sectionList__oe20p::-webkit-scrollbar {
  display: none;
}

.PersonalData_sectionListItem__2exfQ {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  color: var(--color-base-dark);
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  margin: 0 8px;
  padding: 8px 0;
}

.PersonalData_sectionListItemChecked__27OVb {
  background-color: var(--color-base-dark);
  border-radius: 14px;
  color: var(--color-light);
  padding: 8px 16px;
}

.PersonalData_toggleSwitchText__NYCQ3 {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW3);
  padding-right: var(--spacing-xxs);
  color: var(--color-base-dark-sub);
}

.FormContainer_formContainer__1BwKN {
  border-bottom: 1px solid var(--color-gray-10);
}

.FormContainer_formContainer__1BwKN:focus-within {
  border-bottom: 1px solid var(--color-primary-100);
}

.FormContainer_formTitle__2YtuP {
  color: var(--color-base-dark);
  font-family: var(--font-HiraginoSansW3);
  margin-bottom: 4px;
}

.FormContainer_formTitlePrimaryColor__3nw2V {
  color: var(--color-primary-100);
  font-family: var(--font-HiraginoSansW3);
  margin-bottom: 4px;
}

.FormContainer_formTitleLarge__3FMAu {
  font-size: 14px;
}

.FormContainer_formTitleMedium__3sIMH {
  font-size: 12px;
}

.InputBirthDate_selectBirthDate__2Bg_3 {
  -webkit-appearance: none;
          appearance: none;
  background: inherit;
  border: none;
  color: var(--color-base-dark);
  outline: 0;
  font-family: var(--font-JosefinSans);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.06px;
  line-height: 1.7;
  padding: 2px 42px 22px 16px;
  box-sizing: border-box;
  width: 33%;

  /* 矢印アイコン部分 */
  background-image: url(/static/media/arrow-single-down.c61e155c.svg);
  background-position: right 16px top 0;
  background-repeat: no-repeat;
  background-size: 20px;
}

option:disabled {
  background: inherit;
  color: var(--color-base-dark);
}

.InputBirthDate_selectPlaceholder__31ayq {
  color: rgba(18, 18, 18, 0.3);
}

input[type='text'] {
  border: none;
  outline: 0;
  color: var(--color-base-dark);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  letter-spacing: 0.06px;
  box-sizing: border-box;
  width: 100%;
}

.InputText_inputTextLarge__Mu6fn {
  font-size: 24px;
  line-height: 1.4;
  padding: 0 20px;
}

.InputText_inputTextMedium__ML-lS {
  font-size: 14px;
  line-height: 1.7;
  padding: 2px 16px 22px;
}

input[type='text']::-webkit-input-placeholder {
  color: rgba(18, 18, 18, 0.3);
}

input[type='text']:-ms-input-placeholder {
  color: rgba(18, 18, 18, 0.3);
}

input[type='text']::placeholder {
  color: rgba(18, 18, 18, 0.3);
}

select {
  -webkit-appearance: none;
          appearance: none;
  background: inherit;
  border: none;
  color: var(--color-base-dark);
  outline: 0;
  font-family: var(--font-JosefinSans);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.06px;
  line-height: 1.7;
  padding: 2px 42px 22px 16px;
  box-sizing: border-box;
  width: 100%;

  /* 矢印アイコン部分 */
  background-image: url(/static/media/arrow-single-down.c61e155c.svg);
  background-position: right 16px top 0;
  background-repeat: no-repeat;
  background-size: 20px;
}

option:disabled {
  background: inherit;
  color: var(--color-base-dark);
}

.Select_selectPlaceholder__SrkOf {
  color: rgba(18, 18, 18, 0.3);
}

.FormItem_addressSearchButton__bEVdJ {
  width: 80px;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 40px;
}

.FormItemList_errorMessage__2r_G_ {
  font-size: 12px;
  color: var(--color-danger);
}

.AttentionContainer_attentionContainer__xT6xO {
  background-color: #eff4f5;
  border-radius: 16px;
  color: #153f3b;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  padding: 14px var(--spacing-s);
}

.SquareCheckboxWithLabel_checkbox__25VzT {
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.SquareCheckboxWithLabel_checkboxSize__1XrK7 {
  width: 24px;
  height: 24px;
}

.SquareCheckboxWithLabel_checkbox__25VzT.SquareCheckboxWithLabel_checked__1qBYC {
  background-image: url(/static/media/icon-checked.9dd6d634.svg);
}

.SquareCheckboxWithLabel_checkbox__25VzT.SquareCheckboxWithLabel_unchecked__25HSy {
  background-image: url(/static/media/icon-unchecked.aee8ed9c.svg);
}

.SquareCheckboxWithLabel_label__2PqsB {
  color: inherit;
  height: 24px;
  font-size: 12px;
  vertical-align: top;
  margin-left: var(--spacing-xxs);
}

.SquareCheckboxWithLabel_labelLarge__2hRrf {
  color: inherit;
  height: 48px;
  font-size: 14px;
  text-align: left;
  vertical-align: top;
  margin-left: var(--spacing-xxs);
  width: 90%;
}

.PersonalDataEditPage_profilePageWrapper__1-Z0B {
  padding: 16px 0 150px;
  background-color: var(--color-background);
}

.PersonalDataEditPage_profilePageDescription__3cUHQ {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.8;
  text-align: center;
}

.PersonalDataEditPage_profilePageAttentionDescription__11mJB {
  line-height: 1.8;
}

.PersonalDataEditPage_sectionTitle__15RKk {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);
}

.PersonalDataEditPage_formItemList__39dd8 {
  background-color: var(--color-light);
  padding: 10px var(--spacing-s) 0;
}

@media screen and (max-height: 280px) {
  .PersonalDataEditPage_bottomContainer__2dXLa {
    display: none;
  }
}

.ClientCompanyPage_clientCompanyContainer__qA7GH {
  background-color: var(--color-background);
  min-height: 100%;
  padding: var(--spacing-s) 0 150px;
}

.ClientCompanyPage_selectButton__3Y3n6 {
  background-image: url(/static/media/icon-public.53e659e4.svg);
  width: 60px;
  height: 28px;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  cursor: pointer;
}

.ClientCompanyPage_sectionWrapper__1i9nO {
  padding: 12px var(--spacing-s);
}

.ClientCompanyPage_sectionWrapperHighlight__1AaoZ {
  background-color: #d5e9e6;
}

.ClientCompanyPage_sectionTitle__3gXqt {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);
}

.ClientCompanyPage_sectionTitleHighlight__1kfQ1 {
  color: #153f3b;
  font-family: var(--font-HiraginoSansW3);
}

.ClientCompanyPage_clientWrapper__14FMy {
  background-color: var(--color-light);
  font-family: var(--font-HiraginoSansW3);
  padding: 20px var(--spacing-s);
}

.ClientCompanyPage_clientCompanyName__12Apx {
  font-size: 14px;
  color: var(--color-base-dark);
  margin-bottom: 6px;
  padding-right: 40px;
}

.ClientCompanyPage_clientInfoWrapper__iQDa7 {
  position: relative;
  text-decoration: none;
  width: 80%;
}

.ClientCompanyPage_clientInfoWrapper__iQDa7::after {
  content: '';
  display: inline-block;
  background-image: url(/static/media/icon-company.03870190.svg);
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
}

.ClientCompanyPage_clientMajorBrands__1pGJZ {
  color: var(--color-base-dark-sub);
  font-size: 12px;
  padding-right: 40px;
}

.ClientCompanyPage_dividerWrapper__2cM8p {
  background-color: var(--color-light);
  padding-left: var(--spacing-s);
}

.ClientCompanyPage_toggleSwitchText__21Bfz {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW3);
  padding-right: var(--spacing-xxs);
  color: var(--color-base-dark-sub);
}

.OpenExternalWeb_link__2YiRh {
  text-decoration: none;
  color: inherit;
}

.FloatingBackButton_backButton__1G9sx {
  position: fixed;
  bottom: 140px;
  left: var(--spacing-s);
  width: 44px;
  height: 44px;
  border: none;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  background-image: url(/static/media/floating-back-button.91a3a820.svg);
  z-index: 100;
}

/* 縦に短い画面（ソフトウェアキーボードを表示している時など）に被ってしまうので隠す */
@media screen and (max-height: 340px) {
  .FloatingBackButton_backButton__1G9sx {
    display: none;
  }
}

.ClientCompanyDetailPage_companyName__38siQ {
  margin: var(--spacing-m) var(--spacing-s);
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: var(--color-base-dark);
}

.ClientCompanyDetailPage_fieldContainer__1_qNC {
  margin: var(--spacing-m) var(--spacing-s);
}

.ClientCompanyDetailPage_fieldName__2Y3fJ {
  margin-bottom: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--color-base-dark);
}

.ClientCompanyDetailPage_fieldBody__39Z2Q {
  font-family: var(--spacing-xxs);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--color-base-dark);
  white-space: pre-wrap;
}

.ClientCompanyDetailPage_dividerWrapper__j-AOi {
  background-color: var(--color-light);
  padding-left: var(--spacing-s);
}

.ClientCompanyDetailPage_externalWeb__3zD4k {
  margin: var(--spacing-m) var(--spacing-s) var(--spacing-s);
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-primary-100);
}

.ClientCompanyDetailPage_externalWebContainer__1psgf {
  padding-bottom: var(--spacing-3xl);
}

.PointDetailPage_container__2mT43 {
  background-color: var(--color-background);
  padding: var(--spacing-s);
  min-height: 100vh;
}

.PointDetailPage_sectionWrapper__2zNbD {
  background-color: var(--color-light);
  border-radius: 16px;
  color: var(--color-base-dark);
  padding: var(--spacing-s);
  align-items: center;
  overflow-y: scroll;
}

.PointDetailPage_pointSectionWrapper__3H-Ym {
  background-color: var(--color-light);
  border-radius: 8px;
  color: var(--color-base-dark);
  align-items: center;
  box-shadow: 0 1px 3px 0 var(--color-gray-10);
}

.PointDetailPage_pointSection__12Uj3 {
  background-color: var(--color-light);
  border-radius: 8px;
  height: 109px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px 0 var(--color-gray-10);
}

.DailyPoint_pointList__2EIE0 {
  max-height: 516px;
  padding: var(--spacing-s);
  overflow-y: scroll;
}

.DailyPoint_pointListItem__3gDuv {
  padding: 0px;
}

.DailyPoint_monthSectionList__2qUJe {
  padding: 0px;
}

.DailyPoint_monthSectionListItem__39ayI {
  margin: var(--spacing-s) 0;
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: var(--color-base-dark-sub);
}

.DailyPointDetail_monthSection__eLmCF {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.DailyPointDetail_monthSection__eLmCF:last-child {
  border-bottom: none;
}

.DailyPointDetail_monthSectionItem__VHsBA {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: var(--color-base-dark-sub);
}

.DailyPointDetail_monthSectionItem__VHsBA:nth-child(1) {
  width: 30%;
}

.DailyPointDetail_monthSectionItem__VHsBA:nth-child(2) {
  width: 70%;
}

.DailyPointDetail_daySection__cnmwO {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.DailyPointDetail_daySection__cnmwO:last-child {
  border-bottom: none;
}

.DailyPointDetail_daySectionItem__mtYQ4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.DailyPointDetail_daySectionItem__mtYQ4:nth-child(1) {
  width: 60%;
  padding: 0px;
}

.DailyPointDetail_daySectionItem__mtYQ4:nth-child(2) {
  width: 40%;
  padding: 0px;
  text-align: end;
}

.DailyPointDetail_positivePoint__3JB4u {
  color: var(--color-base-dark-sub);
}

.DailyPointDetail_negativePoint__oZSQu {
  padding-right: var(--spacing-s);
  color: var(--color-graph-red);
}

.AuthCodeFormContainer_authCodeForm__3Q1xZ {
  margin: 0 auto;
  height: 44px;
  max-width: 270px;
}

.PhoneNumberAuthenticationPage_body__2Hojk {
  padding-top: var(--spacing-4xl);
}

.PhoneNumberAuthenticationPage_textLarge__1oLUw {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 44px;
  line-height: 1.38;
  font-family: var(--font-HiraginoSansW3);
}

.PhoneNumberAuthenticationPage_textSmall__1Z0eZ {
  text-align: center;
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 0.03px;
  font-family: var(--font-HiraginoSansW3);
}

.PhoneNumberAuthenticationPage_errorMessage__3ekm4 {
  font-size: 10px;
  color: var(--color-danger);
}

.PhoneNumberAuthenticationPage_highlightText__1zrcW {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

@media screen and (max-height: 350px) {
  .PhoneNumberAuthenticationPage_bottomContainer__2qj0H {
    display: none;
  }
}

.InputPassword_input__3SKOG {
  border: none;
  outline: 0;
  color: var(--color-base-dark);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  letter-spacing: 0.06px;
  box-sizing: border-box;
  width: 100%;
}

.InputPassword_inputPasswordLarge__C4l5o {
  font-size: 24px;
  line-height: 1.4;
  padding: 0 48px 0 20px;
}

input[type='password']::-webkit-input-placeholder {
  color: rgba(18, 18, 18, 0.3);
}

input[type='password']:-ms-input-placeholder {
  color: rgba(18, 18, 18, 0.3);
}

input[type='password']::placeholder {
  color: rgba(18, 18, 18, 0.3);
}

.InputPassword_visibleButton__1e0H2 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
          appearance: none;

  float: right;
  position: relative;
  margin-top: -25px;
  margin-right: 0;
  font-size: 12px;
}

.SignInPage_body__21Cc2 {
  padding-top: 56px;
}

.SignInPage_text__24WAY {
  height: 34px;
  font-size: 12px;
  text-align: center;
  line-height: 22px;
}

.SignInPage_highlightText__35-ue {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

.SignInPage_errorMessage__BTQ5T {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .SignInPage_bottomContainer__QKzvW {
    display: none;
  }
}

.TermsOfUseAndPrivacyPolicyPage_container__r-baC {
  display: block;
  font-size: 14px;
  line-height: 1.71;
  overflow: scroll;
  margin-top: var(--spacing-m);
  padding-bottom: 114px;
}

.TermsOfUseAndPrivacyPolicyPage_container__r-baC a {
  color: var(--color-primary-100);
  text-decoration: none;
}

.TermsOfUseAndPrivacyPolicyPage_contentArea__1CXsZ {
  padding: 0 var(--spacing-s) 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.TermsOfUseAndPrivacyPolicyPage_checkbox__1SakU {
  text-align: center;
  margin: 10px 0;
}

.TermsOfUseAndPrivacyPolicyPage_fixedBottom__29Gt1 {
  background: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  z-index: 10;
}

.TermsOfUseAndPrivacyPolicyPage_buttonArea__1ND-4 {
  padding: 8px var(--spacing-s) 58px;
  width: 100%;
}

.BottomSheet_bottomSheetContainer__Syz1d {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  width: calc(100% - 32px);
  padding: 32px 16px 16px 16px;
  background-color: var(--color-light);
}

.BottomSheet_textContainer__3cDS7 {
  height: 100px;
  display: grid;
  white-space: pre-wrap;
}

.BottomSheet_mainContent__1dqm8 {
  font-size: 24px;
  text-align: center;
  font-family: var(--font-HiraginoSansW6);
  line-height: 1.17;
}

.BottomSheet_subContent__2HDbC {
  font-size: 14px;
  font-family: var(--font-HiraginoSansW3);
  text-align: center;
  line-height: 1.71;
}

.BottomSheet_copyright__Cr5yc {
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 320px) {
  .BottomSheet_textContainer__3cDS7 {
    height: 90px;
  }

  .BottomSheet_mainContent__1dqm8 {
    font-size: 20px;
  }

  .BottomSheet_subContent__2HDbC {
    font-size: 12px;
  }

  .BottomSheet_copyright__Cr5yc {
    font-size: 9px;
  }
}

.Pagination_pager__DTxvJ {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  background-color: var(--color-light);
  border-radius: 50px;
}

.Pagination_currentPage__2MA88 {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  background-color: var(--color-primary-100);
  border-radius: 50px;
}

.ServiceDescriptionPage_swiper__117mD {
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.ServiceDescriptionPage_slide__293SI {
  background-color: white;
}

.ServiceDescriptionPage_container__2Pge- {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.ServiceDescriptionPage_paginationContainer__3ihqR {
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
}

.ServiceDescriptionPage_image__2V2ga {
  width: 100%;
}

@media screen and (max-width: 320px) {
  .ServiceDescriptionPage_image__2V2ga {
    height: 260px;
  }
}

.ProgressBar_progressBarContainer__1RtPg {
  background-color: var(--color-surface);
  color: var(--color-base-dark);
  font-family: 'HiraginoSans-W2', sans-serif;
  font-size: 12px;
  padding: 10px var(--spacing-s);
  text-align: right;
}

.ProgressBar_itemStep__v6Awx {
  font-family: var(--font-JosefinSans);
  font-size: 18px;
  font-weight: 300;
  margin: 0 14px 0 4px;
}

.ProgressBar_itemStepStatusWrapper__3kKUJ {
  font-family: var(--font-JosefinSans);
  font-size: 18px;
  font-weight: 300;
}

.ProgressBar_itemCurrentStep__1Q8gF {
  font-size: 32px;
}

.ProgressBar_itemMaxStep__6xn3q {
  margin-left: 2px;
  color: var(--color-base-dark-sub);
}

.ProgressBar_progressBar__1NXZm {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  display: block;
  width: 100%;
  height: 5px;
}

::-webkit-progress-bar {
  background-color: #f1eff5;
}

::-webkit-progress-value {
  background-color: var(--color-primary-100);
}

::-moz-progress-bar {
  background-color: var(--color-primary-100);
}

.EmailAuthenticationPage_topMessage__WxVt9 {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
}

.EmailAuthenticationPage_highlightText__1lClT {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

.EmailAuthenticationPage_text__36THL {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.EmailAuthenticationPage_errorMessage__2z82S {
  font-size: 10px;
  color: var(--color-danger);
}

.EmailRegistrationPage_topMessage__1Ab5U {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-xl);
  margin: 0;
}

.EmailRegistrationPage_helpLink__2Wsl5 {
  display: block;
  margin-left: auto;
  font-size: 12px;
  font-weight: bold;
  color: var(--color-primary-100);
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  cursor: pointer;
}

.EmailRegistrationPage_errorMessage__1O_NB {
  font-size: 10px;
  color: var(--color-danger);
}

.EmailRegistrationPage_contentArea__l3-Yw {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 145px;
}

@media screen and (max-height: 280px) {
  .EmailRegistrationPage_bottomContainer__3xL4s {
    display: none;
  }
}

.InputTel_input__1RU3T {
  border: none;
  outline: 0;
  color: var(--color-base-dark);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  letter-spacing: 0.06px;
  box-sizing: border-box;
  width: 100%;
}

.InputTel_inputTelLarge__2yyDO {
  font-size: 24px;
  line-height: 1.4;
  padding: 0 20px;
}

.InputTel_inputTelMedium__2nVvu {
  font-size: 14px;
  line-height: 1.7;
  padding: 2px 16px 22px;
}

input[type='tel']::-webkit-input-placeholder {
  color: rgba(18, 18, 18, 0.3);
}

input[type='tel']:-ms-input-placeholder {
  color: rgba(18, 18, 18, 0.3);
}

input[type='tel']::placeholder {
  color: rgba(18, 18, 18, 0.3);
}

.PhoneNumberRegistrationPage_topMessage__pjNPo {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.PhoneNumberRegistrationPage_errorMessage__1jbHV {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .PhoneNumberRegistrationPage_bottomContainer__1J5ox {
    display: none;
  }
}

.PhoneNumberAuthenticationPage_body__3FiAV {
  padding-top: 24px;
}

.PhoneNumberAuthenticationPage_textLarge__6mGSO {
  font-size: 16px;
  text-align: center;
  line-height: 1.38;
}

.PhoneNumberAuthenticationPage_textSmall__2P7ip {
  font-size: 12px;
  text-align: center;
}

.PhoneNumberAuthenticationPage_highlightText__12V8U {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

.PhoneNumberAuthenticationPage_highlightTextLarge__OMhYj {
  font-size: 16px;
  text-align: center;
  color: var(--color-primary-100);
}

.PhoneNumberAuthenticationPage_errorMessage__3VyHa {
  font-size: 10px;
  color: var(--color-danger);
}

.PhoneNumberAuthenticationTipsPage_body__6-dQL {
  padding-top: 40px;
}

.PhoneNumberAuthenticationTipsPage_imageContainer__38HlQ {
  width: 100%;
  text-align: center;
}

.PhoneNumberAuthenticationTipsPage_image__1B5Qo {
  max-width: 344px;
  max-height: 258px;
  object-fit: contain;
}

.PhoneNumberAuthenticationTipsPage_label__9q9sV {
  font-size: 16px;
  color: var(--color-base-dark);
  font-weight: bold;
  text-align: center;
}

.PhoneNumberAuthenticationTipsPage_text__74VYG {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 0;
}

.PasswordPage_body__rga8N {
  padding-top: var(--spacing-m);
}

.PasswordPage_text__2PuEd {
  font-size: 12px;
  text-align: center;
  line-height: 22px;
}

.PasswordPage_errorMessage__2xXit {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .PasswordPage_bottomContainer__2HELc {
    display: none;
  }
}

.ProfilePage_profilePageWrapper__3-Kkq {
  padding: 60px var(--spacing-s) 150px;
}

.ProfilePage_profilePageDescription__1xuyQ {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.8;
  text-align: center;
}

.ProfilePage_profilePageAttentionDescription__2p2lh {
  line-height: 1.8;
}

.ProfilePage_profilePageDescriptionWarning__1BIfq {
  color: var(--color-danger);
}

.ProfileDetailPage_profileDetailPageWrapper__29goQ {
  padding: 60px var(--spacing-s) 150px;
}

.ProfileDetailPage_sectionTitle__1RUJ8 {
  font-size: 16px;
  font-family: var(--font-HiraginoSansW6);
  margin-bottom: var(--spacing-m);
}

.ProfileDetailPage_profilePageDescription__1lxTO {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.8;
  text-align: center;
}

.ProfileDetailPage_profilePageDescriptionWarning__33MjN {
  color: var(--color-danger);
}

.ProfileDescriptionPage_descriptionPageWrapper__3PR28 {
  color: var(--color-base-dark);
  padding: var(--spacing-3xl) var(--spacing-s) 156px;
  text-align: center;
}

.ProfileDescriptionPage_descriptionPageTitle__2ITMo {
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  line-height: 1.5;
}

.ProfileDescriptionPage_descriptionPageDescription__2yVYg {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.7;
}

.ProfileConfirmPage_confirmPageWrapper__1PIB4 {
  font-size: 14px;
  font-family: var(--font-HiraginoSansW3);
  padding: 60px var(--spacing-s) var(--spacing-m);
}

.ProfileConfirmPage_sectionTitle__2N5yM {
  font-size: 16px;
  font-family: var(--font-HiraginoSansW6);
  margin-bottom: var(--spacing-m);
}

.ProfileConfirmPage_inputItem__35JBf {
  line-height: 1.7;
  padding: 2px 16px 22px;
}

.ProfileConfirmPage_inputNoItem__3qE54 {
  color: var(--color-gray-30);
}

.ProfileConfirmPage_confirmDescription__2H_3m {
  line-height: 1.7;
  text-align: center;
}

.AgreementPage_pageWrapper__1sCBq {
  padding: 60px var(--spacing-s) 150px;
}

.AgreementPage_profilePageWrapper__33i-i {
  padding: 60px var(--spacing-s) 150px;
}

.AgreementPage_profilePageDescription__3AOem {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.8;
  text-align: center;
}

.AgreementPage_profilePageAttentionDescription__2WiUw {
  line-height: 1.8;
}

.AgreementPage_profilePageDescriptionWarning__18OAm {
  color: var(--color-danger);
}

.AgreementPage_checkbox__3hIK- {
  display: flex;
}

.AgreementPage_errorMessage__3fv2Q {
  font-size: 12px;
  color: var(--color-danger);
}

/* NOTE(smile-yoshryo): 基本的に横スクロール制御の範疇として幅はカルーセル側で制御される */
.ProgramListCell_bannerImage__m3g00 {
  height: 168px;
  border-radius: 16px;
}

.ProgramListCell_termDescriptionMask__3jPfF {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  border-radius: 0 0 16px 16px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 99%);
}

.ProgramListCell_termDescription__GZhqx {
  position: absolute;
  text-align: right;
  bottom: 16px;
  right: 16px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  color: var(--color-light);
}

.ProgramListCell_title__M-HNl {
  text-align: left;
  margin-top: 16px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 14px;
  color: var(--color-base-dark);
}

.ProgramListCell_scoreDescription__1HrSP {
  text-align: left;
  margin-top: 4px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.ProgramTopPage_topNoticeText__3103R {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.ProgramTopPage_container__3c_JW {
  background-color: var(--color-background);
  min-height: 100%;
  padding: var(--spacing-s);
}

.ProgramTopPage_contentsTitle__1ANTq {
  font-size: 18px;
  font-weight: bold;
  margin: var(--spacing-s) 0;
}

.ProgramTopPage_contentsDescription__UPBco {
  margin-bottom: 28px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.ProgramTopPage_emptyJoinedProgram__rZwTE {
  margin-top: var(--spacing-m);
  width: 100%;
  height: 236px;
  text-align: center;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.ProgressBar_background__w-cB3 {
  width: 100%;
  height: 8px;
  background-color: #f4f5f7;
  border-radius: 16px;
}

.ActionListCell_container__1JgaZ {
  overflow: hidden;
  position: relative;
  width: 100%;

  display: flex;
}

.ActionListCell_leftArea__1b9X1 {
  display: table;
}

.ActionListCell_leftArea__1b9X1 .ActionListCell_leftAreaChild__TC9sT {
  display: table-cell;
  vertical-align: middle;
}

.ActionListCell_rewardIconArea__10gml {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  background-color: #f4f5f7;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
}

.ActionListCell_rewardIconArea__10gml .ActionListCell_icon__3QXUP {
  margin: auto;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
}

.ActionListCell_rewardIconArea__10gml .ActionListCell_icon__3QXUP img {
  width: auto;
  height: 100%;
}

.ActionListCell_rewardIconArea__10gml .ActionListCell_value__br_7P {
  margin: auto;
  width: 36px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 10px;
  color: var(--color-base-dark);
}

.ActionListCell_summaryArea__1hMuQ {
  width: 100%;
  padding: 8px 0 8px 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.ActionListCell_summaryArea__1hMuQ .ActionListCell_title__3yYfP {
  text-align: left;
  width: 100%;
  font-family: var(--font-HiraginoSansW6);
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-base-dark);
}

.ActionListCell_summaryArea__1hMuQ .ActionListCell_underContentsArea__397Kx {
  display: flex;
}

.ActionListCell_underContentsArea__397Kx .ActionListCell_progressRateArea__382Ob {
  width: 100%;
}

.ActionListCell_progressRateArea__382Ob .ActionListCell_progressTextValue__1EBIh {
  text-align: end;
}

.ActionListCell_progressTextValue__1EBIh .ActionListCell_numerator__2pXfa {
  display: inline-block;
  font-family: var(--font-HiraginoSansW3);
  font-size: 16px;
  margin-right: 4px;
  color: var(--color-base-dark);
}

.ActionListCell_progressTextValue__1EBIh .ActionListCell_otherValue__jCTQO {
  display: inline-block;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #d0d2d5;
}

.ActionListCell_progressRateArea__382Ob .ActionListCell_progressBar__2q5aL {
  height: 8px;
  width: 100%;
  text-align: left;
}

.MissionPanelListCell_container__33Yti {
  padding: var(--spacing-s) 4px; /* 影が見切れる分だけのサイドpadding */
}

.MissionPanelListCell_title__3bLYu {
  font-family: var(--font-HiraginoSansW6);
  font-size: 22px;
  white-space: pre-wrap;
  color: var(--color-base-dark);
}

.MissionPanelListCell_termDescription__lWrCF {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

@media screen and (max-width: 320px) {
  .MissionPanelListCell_termDescription__lWrCF {
    font-size: 11px;
  }
}

.MissionPanelListCell_panel__2xunY {
  padding: var(--spacing-s);
  margin: var(--spacing-s) auto var(--spacing-l) auto;
  width: 100%;
  border-radius: var(--spacing-s);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--color-light);
}

.MissionPanelListCell_hr__1K_xw {
  width: 100%;
  height: 1px;
  background-color: #ececec;
}

.ProgramDetailPage_headerBanner__90buQ {
  position: relative;
}

.ProgramDetailPage_headerBanner__90buQ img {
  width: 100%;
}

.ProgramDetailPage_container__Eszcm {
  background-color: var(--color-background);
  padding: var(--spacing-s) var(--spacing-s) 120px var(--spacing-s); /* NOTE: フロートボタン分下に空間を設けたいので多めに。ページの下端にボタンサイズを含めた形で領域を確保する必要がある都合上汎用的な値が適用できない。 */
}

.ProgramDetailPage_title__2RaLI {
  margin-top: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW6);
  font-size: 22px;
  line-height: 1.32;
}

.ProgramDetailPage_termDescription__24kZA {
  margin-top: var(--spacing-m);
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.5;
}

.ProgramDetailPage_keywordArea__1Dy-N {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  margin-right: -8px; /* 子要素の横並び端のマージンがはみ出るので調整 */
}

.ProgramDetailPage_keywordArea__1Dy-N .ProgramDetailPage_keyword__3bmE7 {
  border: solid 1px black;
  border-radius: 20px;
  padding: var(--spacing-xxs) var(--spacing-s);
  margin-right: var(--spacing-xxs);
  margin-bottom: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.5;
}

.ProgramDetailPage_hr__1rWSa {
  width: 100%;
  height: 1px;
  background-color: rgba(18, 18, 18, 0.1);
}

.ProgramDetailPage_description__3i0P9 {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.5;
  margin: var(--spacing-l) 0;
  white-space: pre-wrap;
}

.ProgramDetailPage_missionPanelTitle__8Xl_B {
  margin-top: var(--spacing-l);
  font-family: var(--font-HiraginoSansW6);
  font-size: 22px;
  line-height: 1.32;
  color: #121212;
}

.ProgramDetailPage_disclaimerText__39FyQ {
  margin-top: var(--spacing-s);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  white-space: pre-wrap;
  color: var(--color-base-dark-sub);
}

.ProgramDetailPage_noticeTitle__1WGsW {
  margin-top: 68px;
  margin-bottom: 20px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  text-align: center;
  color: var(--color-base-dark);
}

.ProgramDetailPage_noticeDescription__1M0WU {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  white-space: pre-wrap;
  color: var(--color-base-dark-sub);
}

.ProgramDetailPage_noticeContentsTitle__1nMj5 {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-m);
  padding: var(--spacing-xxs) var(--spacing-xs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
  background-color: #e7e0d8;
}

.ProgramDetailPage_floatButton__1R8Ns {
  margin: 0;
  bottom: 100px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 80%;
  position: fixed;
  z-index: 1;
}

.CampaignPage_container__1bnGI {
  background-color: var(--color-background);
  min-height: 100%;
}

.CampaignPage_questionSection__2RRdv {
  padding: var(--spacing-s);
}

.CampaignPage_space__1RMsi {
  height: 48px;
}

.QuestionsList_questionTitle__1b86z {
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  line-height: 1.5;
  color: var(--color-base-dark);
}

.InputNum_input__1y46Y {
  border: none;
  outline: 0;
  color: var(--color-base-dark);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  letter-spacing: 0.06px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

.InputNum_inputNumLarge__2QT5W {
  font-size: 32px;
  line-height: 1.4;
  padding: 0 20px;
}

.InputNum_inputNumSmall__2Wr5z {
  font-size: 32px;
  padding: var(--spacing-xxs) 0 0 0;
}

input[type='tel']::-webkit-input-placeholder {
  color: rgba(18, 18, 18, 0.3);
}

input[type='tel']:-ms-input-placeholder {
  color: rgba(18, 18, 18, 0.3);
}

input[type='tel']::placeholder {
  color: rgba(18, 18, 18, 0.3);
}

.HontoCouponPage_container__2ioZq {
  padding: var(--spacing-s) 0;
  min-height: 100vh;
  background-color: var(--color-background);
}

.HontoCouponPage_noticeArea___OMsj {
  padding: 0 var(--spacing-s);
}

.HontoCouponPage_contentsArea__2n3Mg {
  margin: var(--spacing-xxs) var(--spacing-s) var(--spacing-xxl)
    var(--spacing-s);
  padding: var(--spacing-m) var(--spacing-s);
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  background-color: var(--color-light);
}

.HontoCouponPage_ownedPointTitle__2sY5i {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.HontoCouponPage_ownedPointRoot__-Nx5g {
  display: flex;
  align-items: center;
}

.HontoCouponPage_ownedPointRoot__-Nx5g .HontoCouponPage_point__240F6 {
  margin-top: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  font-size: 32px;
  color: #000;
}

.HontoCouponPage_ownedPointRoot__-Nx5g .HontoCouponPage_suffix__39YI1 {
  margin-top: var(--spacing-xxs);
}

.HontoCouponPage_commonValueSuffix__3xsC5 {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.HontoCouponPage_exchangeGuide__1OvN9 {
  margin-top: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.HontoCouponPage_couponArea__kwm7b {
  margin-top: var(--spacing-s);
}

.HontoCouponPage_sentenceDetailRoot__2Oo9H {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.HontoCouponPage_sentenceDetailRoot__2Oo9H .HontoCouponPage_couponTitle__3alOu {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.HontoCouponPage_valueAndQuantityPair__h4tZ_ {
  display: flex;
  align-items: flex-end;
}

.HontoCouponPage_valueAndQuantityPair__h4tZ_ .HontoCouponPage_exchangeCouponValue__2oBcf {
  margin-right: var(--spacing-3xs);
  width: 62px;
  height: 48px;
  font-family: var(--font-JosefinSans);
  text-align: center;
  font-size: 32px;
  border-radius: 6px;
  border: solid 1px #cbcbcb;
  color: #000;
}

.HontoCouponPage_dividerWrapper__1Ar1r {
  margin-top: var(--spacing-s);
}

.HontoCouponPage_resultGuideTitle__2LuAA {
  margin-top: var(--spacing-m);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.HontoCouponPage_resultDetailRoot__1ADmc {
  margin-top: var(--spacing-xxs);
  display: flex;
  flex-flow: column;
  grid-gap: 16px 0;
  gap: 16px 0;
}

.HontoCouponPage_resultDetailTitle__2XdE7 {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.HontoCouponPage_resultNumValue__3LCb5 {
  margin-right: var(--spacing-3xs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #f2554f;
}

.HontoCouponPage_insufficientPoints__1b5xo {
  margin-top: var(--spacing-m);
  text-align: center;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #f2554f;
}

.HontoCouponPage_email__T1xVm {
  padding: var(--spacing-s);
  margin-top: var(--spacing-m);
  border-radius: 16px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  text-align: center;
  white-space: pre-wrap;
  background-color: #eff4f5;
}

.HontoCouponPage_exchangeButton__3iPuI {
  margin-top: var(--spacing-m);
}

.FincCouponPage_container__1tB5R {
  padding: var(--spacing-s) 0;
  min-height: 100vh;
  background-color: var(--color-background);
}

.FincCouponPage_noticeArea__GfMr4 {
  padding: 0 var(--spacing-s);
}

.FincCouponPage_contentsArea__2N-nO {
  margin: var(--spacing-xxs) var(--spacing-s) var(--spacing-xxl)
    var(--spacing-s);
  padding: var(--spacing-m) var(--spacing-s);
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  background-color: var(--color-light);
}

.FincCouponPage_ownedPointTitle__2VVJx {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.FincCouponPage_ownedPointRoot__3qwjV {
  display: flex;
  align-items: center;
}

.FincCouponPage_ownedPointRoot__3qwjV .FincCouponPage_point__2soTV {
  margin-top: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  font-size: 32px;
  color: #000;
}

.FincCouponPage_ownedPointRoot__3qwjV .FincCouponPage_suffix__2zPMQ {
  margin-top: var(--spacing-xxs);
}

.FincCouponPage_commonValueSuffix__3Dtz- {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.FincCouponPage_exchangeGuide__3dCYb {
  margin-top: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.FincCouponPage_couponArea__9GdqM {
  margin-top: var(--spacing-s);
}

.FincCouponPage_sentenceDetailRoot__2UsFP {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.FincCouponPage_sentenceDetailRoot__2UsFP .FincCouponPage_couponTitle__3h2Y1 {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.FincCouponPage_valueAndQuantityPair__2lM0B {
  display: flex;
  align-items: flex-end;
}

.FincCouponPage_valueAndQuantityPair__2lM0B .FincCouponPage_exchangeCouponValue__22lFb {
  margin-right: var(--spacing-3xs);
  width: 62px;
  height: 48px;
  font-family: var(--font-JosefinSans);
  text-align: center;
  font-size: 32px;
  border-radius: 6px;
  border: solid 1px #cbcbcb;
  color: #000;
}

.FincCouponPage_dividerWrapper__2vboh {
  margin-top: var(--spacing-s);
}

.FincCouponPage_resultGuideTitle__2v_V9 {
  margin-top: var(--spacing-m);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.FincCouponPage_resultDetailRoot__t0bpN {
  margin-top: var(--spacing-xxs);
  display: flex;
  flex-flow: column;
  grid-gap: 16px 0;
  gap: 16px 0;
}

.FincCouponPage_resultDetailTitle__3sQyK {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
}

.FincCouponPage_resultNumValue__1TXKf {
  margin-right: var(--spacing-3xs);
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #f2554f;
}

.FincCouponPage_insufficientPoints__3D9Dw {
  margin-top: var(--spacing-m);
  text-align: center;
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: #f2554f;
}

.FincCouponPage_exchangeButton__xX9KM {
  margin-top: var(--spacing-m);
}

.NoticeListCell_bodyButton__2YlAh {
  position: relative;
  width: 100%;
  border: none;
  background-color: transparent;
  line-height: 1.7;
  text-align: left;
  border-bottom: 1px solid #ecf0f7;
  padding: var(--spacing-s) 0;
}

.NoticeListCell_bodyButton__2YlAh .NoticeListCell_unreadBadge__2cbx- {
  line-height: 1;
  position: absolute;
  top: 8px;
  right: 8px;
}

.NoticeListCell_title__3sVnq {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.71;
  color: var(--color-base-dark);
  padding-right: 14px;
}

.NoticeListCell_summary__3z9lX {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.67;
  color: #979797;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  white-space: pre-wrap;
}

.NoticeTopPage_container__3aV7p {
  min-height: 100%;
  padding: var(--spacing-s);
}

.NoticeDetailPage_container__1jar6 {
  padding: var(--spacing-s);
  min-height: 100%;
}

.NoticeDetailPage_registeredAt__1LOvF {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.67;
  color: var(--color-base-dark-sub);
}

.NoticeDetailPage_title__3rj0u {
  margin-top: var(--spacing-xxs);
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-base-dark);
}

.NoticeDetailPage_description__1WTzN {
  margin-top: var(--spacing-s);
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.71;
  white-space: pre-wrap;
  color: var(--color-base-dark);
}

.SettingsContentsListCell_button__2IdvO {
  width: 100%;
  height: 60px;
  padding: 0 var(--spacing-s);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;
}

.SettingsContentsListCell_title__3Ls7m {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.SettingsContentsListCell_rightArrow__1qn9C {
  width: 24px;
  height: 24px;
}

.SettingsRootPage_noticeArea__1TVco {
  padding: var(--spacing-l) var(--spacing-s);
  display: flex;
  flex-direction: column;
  grid-gap: var(--spacing-m);
  gap: var(--spacing-m);
}

.SettingsRootPage_notice__2CDLE {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark-sub);
  white-space: pre-wrap;
}

.SettingsRootPage_notice__2CDLE .SettingsRootPage_child__3dmMz {
  margin-left: var(--spacing-xs);
}

.SettingsRootPage_logoutButtonWrapper__20jTQ {
  margin: 80px var(--spacing-s) var(--spacing-xl) var(--spacing-s);
}

.AcountSettingsMenuListCell_button__2bmh5 {
  width: 100%;
  height: 60px;
  padding: 0 var(--spacing-s);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;
}

.AcountSettingsMenuListCell_menuTitle__kbHVs {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.AcountSettingsMenuListCell_rightArrow__1r_Ev {
  width: 24px;
  height: 24px;
}

.AcountSettingsCategoryList_category__VfF5r {
  display: flex;
  align-items: center;
  height: 56px;
  text-align: left;
  background-color: var(--color-background);
}

.AcountSettingsCategoryList_category__VfF5r .AcountSettingsCategoryList_text__l6LbI {
  padding-left: var(--spacing-s);
  font-family: var(--font-HiraginoSansW6);
  font-size: 12px;
  color: var(--color-base-dark);
}

.PhoneNumberResettingsPage_topMessage__2Q2Hf {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.PhoneNumberResettingsPage_errorMessage__2c8py {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .PhoneNumberResettingsPage_bottomContainer__1WKqp {
    display: none;
  }
}

.PhoneNumberAuthenticationPage_body__33Omx {
  padding-top: 24px;
}

.PhoneNumberAuthenticationPage_textLarge__11iyu {
  font-size: 16px;
  text-align: center;
  line-height: 1.38;
}

.PhoneNumberAuthenticationPage_textSmall__2CGyo {
  font-size: 12px;
  text-align: center;
}

.PhoneNumberAuthenticationPage_highlightText__3JSpK {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

.PhoneNumberAuthenticationPage_highlightTextLarge__27ful {
  font-size: 16px;
  text-align: center;
  color: var(--color-primary-100);
}

.PhoneNumberAuthenticationPage_errorMessage__1AHMz {
  font-size: 10px;
  color: var(--color-danger);
}

.PushNotificationSettingsListCell_container__3KZpZ {
  height: 60px;
  margin: 0 var(--spacing-s);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.PushNotificationSettingsListCell_name__3X00E {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: #242424;
}

.InputEmailPage_topMessage__vdf6p {
  font-size: 18px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.InputEmailPage_message__2kgdr {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.InputEmailPage_errorMessage__1RVLZ {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .InputEmailPage_bottomContainer__3NiO- {
    display: none;
  }
}

.InputCodePage_topMessage__k28dW {
  font-size: 18px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.InputCodePage_message__g13gY {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.InputCodePage_errorMessage__3pehX {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .InputCodePage_bottomContainer__1XUUe {
    display: none;
  }
}

.NewPasswordPage_errorMessage__YnSA6 {
  font-size: 10px;
  color: var(--color-danger);
}

.NewPasswordPage_message__2hosF {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.ConfirmPhoneNumberPage_topMessage__2H5hD {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.ConfirmPhoneNumberPage_errorMessage___ZpbA {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .ConfirmPhoneNumberPage_bottomContainer__dl4au {
    display: none;
  }
}

.ConfirmBirthdayPage_topMessage__1RTIZ {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.ConfirmBirthdayPage_errorMessage__2h1mP {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .ConfirmBirthdayPage_bottomContainer__lI3AS {
    display: none;
  }
}


.NotifyEmailPage_topMessage__3f1CI {
  font-size: 18px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.NotifyEmailPage_message__32FBr {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.NotifyEmailPage_errorMessage__2_NdM {
  font-size: 10px;
  color: var(--color-danger);
}

@media screen and (max-height: 200px) {
  .NotifyEmailPage_bottomContainer__2-NW_ {
    display: none;
  }
}

.NotifyEmailCompletePage_topMessage__2cpAt {
  font-size: 18px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

.NotifyEmailCompletePage_message__3Trqe {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}

@media screen and (max-height: 200px) {
  .NotifyEmailCompletePage_bottomContainer__qFqz9 {
    display: none;
  }
}

.WithdrawalSettingsPage_container__2YRuW {
  padding: var(--spacing-m) var(--spacing-s) var(--spacing-3xl) var(--spacing-s);
}

.WithdrawalSettingsPage_title__15fr7 {
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  color: var(--color-base-dark);
}

.WithdrawalSettingsPage_description__RT_Fj {
  margin: var(--spacing-m) 0 20px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.71;
  white-space: pre-wrap;
}

.WithdrawalSettingsPage_checksRoot__3819H {
  display: flex;
  flex-direction: column;
}

.WithdrawalSettingsPage_check__2hG-a {
  display: flex;
  align-items: center;
}

.WithdrawalSettingsPage_opinionAndRequestTitle__2m8TO {
  margin-top: 20px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  color: var(--color-base-dark);
}

.WithdrawalSettingsPage_opinionAndRequest__1EE4v {
  margin-top: var(--spacing-xxs);
  padding: var(--spacing-xxs);
  resize: none;
  box-sizing: border-box;
  width: 100%;
  height: 192px;
  border-radius: 8px;
  border: solid 1.5px #acb1c0;
  background: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.WithdrawalSettingsPage_opinionAndRequest__1EE4v:focus {
  outline: none;
}

.WithdrawalSettingsPage_button__1CxW0 {
  margin-top: var(--spacing-4xl);
}

.WithdrawalSettingsPage_errorMessage__1fOXm {
  font-size: 12px;
  color: var(--color-danger);
}

.WithdrawalCompletedPage_container__fppJk {
  padding: 0 var(--spacing-s);
}

.WithdrawalCompletedPage_message__2Z0y7 {
  padding-top: var(--spacing-m);
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  color: var(--color-base-dark);
}

.WithdrawalCompletedPage_button__1KI21 {
  margin-top: 160px;
}

.PasswordResettingsPage_errorMessage__1xGlX {
  font-size: 10px;
  color: var(--color-danger);
}

.EmailResettingsPage_body__1zjUp {
  padding-top: var(--spacing-m);
}

.EmailResettingsPage_errorMessage__2dtRC {
  font-size: 10px;
  color: var(--color-danger);
}

.EmailResettingsAuthenticatonPage_topMessage__q9TyW {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
}

.EmailResettingsAuthenticatonPage_highlightText__2Skbp {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

.EmailResettingsAuthenticatonPage_text__3mbMj {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.EmailResettingsAuthenticatonPage_errorMessage__rdlKl {
  font-size: 10px;
  color: var(--color-danger);
}

.GlobalNavigation_globalNavigation__2b55v {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 0 7px 0 rgba(0, 89, 141, 0.1);
  z-index: 100;
  background-color: var(--color-light);
}

.GlobalNavigation_navigationItem__2_Gwv {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border: none;
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  background-color: var(--color-light);
}

.GlobalNavigation_icon__3daNa {
  width: 32px;
  height: 32px;
  margin-top: 6px;
  background-color: var(--color-base-disabled);
}

.GlobalNavigation_icon__3daNa.GlobalNavigation_home__3IOrO {
  -webkit-mask-image: url(/static/media/icon-home.238119c4.svg);
          mask-image: url(/static/media/icon-home.238119c4.svg);
}

.GlobalNavigation_icon__3daNa.GlobalNavigation_offer__32-a1 {
  -webkit-mask-image: url(/static/media/icon-offer.9384c462.svg);
          mask-image: url(/static/media/icon-offer.9384c462.svg);
}

.GlobalNavigation_icon__3daNa.GlobalNavigation_program__2QSVW {
  -webkit-mask-image: url(/static/media/icon-program.f676db44.svg);
          mask-image: url(/static/media/icon-program.f676db44.svg);
}

.GlobalNavigation_icon__3daNa.GlobalNavigation_mydata__1xvxk {
  -webkit-mask-image: url(/static/media/icon-mydata.a19445a8.svg);
          mask-image: url(/static/media/icon-mydata.a19445a8.svg);
}

.GlobalNavigation_icon__3daNa.GlobalNavigation_selfCheck__2Jkk8 {
  -webkit-mask-image: url(/static/media/icon-self-check.a6d798ff.svg);
          mask-image: url(/static/media/icon-self-check.a6d798ff.svg);
}

.GlobalNavigation_navigationItem__2_Gwv.GlobalNavigation_itemSelected__LG4PS .GlobalNavigation_icon__3daNa {
  background-color: #003543;
}

.GlobalNavigation_title__1h5Fg {
  font-size: 10px;
  color: var(--color-base-disabled);
  margin-top: 5px;
  white-space: pre-wrap;
  line-height: 1;
}

.GlobalNavigation_navigationItem__2_Gwv.GlobalNavigation_itemSelected__LG4PS .GlobalNavigation_title__1h5Fg {
  color: #003543;
}

.GlobalNavigation_batch__30uW- {
  position: absolute;
  top: 2px;
  right: 10px;
  color: var(--color-light);
  background-color: #fb3737;
  font-size: 12px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 20px;
  border: 2px solid var(--color-light);
}

.GlobalNavigation_batch__30uW-.GlobalNavigation_largeNumber__3RrhJ {
  right: 0;
  width: 30px;
}

.ClientEnvIdentifier_ribbonWrapper__etDaz {
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  overflow: hidden;
  z-index: 100;
  padding: 0;
}

.ClientEnvIdentifier_ribbonText__1Buzl {
  color: #fff;
  font-size: 10px;
  letter-spacing: 0.8px;
  text-align: center;
  opacity: 0.7;
  padding: 4px 0;
  position: absolute;
  left: -16px;
  top: 20px;
  width: 130px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

