.checkbox {
  background-color: transparent;
  border: none;
  appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.checkboxSize {
  width: 24px;
  height: 24px;
}

.checkbox.checked {
  background-image: url('./icon-checked.svg');
}

.checkbox.unchecked {
  background-image: url('./icon-unchecked.svg');
}

.label {
  color: inherit;
  height: 24px;
  font-size: 12px;
  vertical-align: top;
  margin-left: var(--spacing-xxs);
}

.labelLarge {
  color: inherit;
  height: 48px;
  font-size: 14px;
  text-align: left;
  vertical-align: top;
  margin-left: var(--spacing-xxs);
  width: 90%;
}
