.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  outline: none;
  position: relative;
}

.buttonLarge {
  width: 100%;
  height: 48px;
  border-radius: 28px;
  font-family: var(--font-HiraginoSansW6);
  /* ボタンテキストは最長全角12文字を想定 */
  font-size: 18px;
}

.buttonSmall {
  width: 100%;
  height: 32px;
  border-radius: 16px;
  font-family: var(--font-NotoSans);
  font-weight: 500;
  font-size: 12px;
  padding: 0 20px;
  white-space: nowrap;
}

.buttonDefault {
  color: var(--color-primary-100);
  background-color: var(--color-light);
  border: solid 1px var(--color-primary-100);
}

.buttonPrimary {
  color: var(--color-light);
  background-color: var(--color-primary-100);
  border: 0;
}

.buttonDestructive {
  color: #f2554f;
  background-color: var(--color-light);
  border: solid 1px #f2554f;
}

.buttonDisabled {
  color: var(--color-light);
  background-color: var(--color-disabled);
}

.iconExternal {
  padding-right: 16px;
}

.iconExternal::after {
  content: '';
  background-image: url('global/images/icon-external.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  margin-left: 6px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
