.swiper {
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.slide {
  background-color: white;
}

.container {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.paginationContainer {
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
}

.image {
  width: 100%;
}

@media screen and (max-width: 320px) {
  .image {
    height: 260px;
  }
}
