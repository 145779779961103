.ribbonWrapper {
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  overflow: hidden;
  z-index: 100;
  padding: 0;
}

.ribbonText {
  color: #fff;
  font-size: 10px;
  letter-spacing: 0.8px;
  text-align: center;
  opacity: 0.7;
  padding: 4px 0;
  position: absolute;
  left: -16px;
  top: 20px;
  width: 130px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
