.backButton {
  position: fixed;
  bottom: 140px;
  left: var(--spacing-s);
  width: 44px;
  height: 44px;
  border: none;
  outline: none;
  padding: 0;
  appearance: none;
  background-color: transparent;
  background-image: url('./floating-back-button.svg');
  z-index: 100;
}

/* 縦に短い画面（ソフトウェアキーボードを表示している時など）に被ってしまうので隠す */
@media screen and (max-height: 340px) {
  .backButton {
    display: none;
  }
}
