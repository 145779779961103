.formContainer {
  border-bottom: 1px solid var(--color-gray-10);
}

.formContainer:focus-within {
  border-bottom: 1px solid var(--color-primary-100);
}

.formTitle {
  color: var(--color-base-dark);
  font-family: var(--font-HiraginoSansW3);
  margin-bottom: 4px;
}

.formTitlePrimaryColor {
  color: var(--color-primary-100);
  font-family: var(--font-HiraginoSansW3);
  margin-bottom: 4px;
}

.formTitleLarge {
  font-size: 14px;
}

.formTitleMedium {
  font-size: 12px;
}
