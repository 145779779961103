.container {
  background-color: var(--color-background);
  padding: var(--spacing-s);
  min-height: 100vh;
}

.sectionWrapper {
  background-color: var(--color-light);
  border-radius: 16px;
  color: var(--color-base-dark);
  padding: var(--spacing-s);
  align-items: center;
  overflow-y: scroll;
}

.pointSectionWrapper {
  background-color: var(--color-light);
  border-radius: 8px;
  color: var(--color-base-dark);
  align-items: center;
  box-shadow: 0 1px 3px 0 var(--color-gray-10);
}

.pointSection {
  background-color: var(--color-light);
  border-radius: 8px;
  height: 109px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px 0 var(--color-gray-10);
}
