@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/sf-ui-text-2');

:root {
  height: 100%;
  /** colors */
  --color-base-dark: #101010;
  --color-base-dark-sub: rgba(16, 16, 16, 0.5);
  --color-base-disabled: rgba(16, 16, 16, 0.38);
  --color-light: #ffffff;
  --color-light-sub: rgba(255, 255, 255, 0.5);
  --color-surface: #fffaf5;
  --color-disabled: #e0e0e0;
  --color-primary-100: #009d85;
  --color-primary-50: rgba(0, 157, 133, 0.5);
  --color-primary-20: rgba(0, 157, 133, 0.2);
  --color-primary-10: rgba(0, 157, 133, 0.1);
  --color-secondary-100: #ff634c;
  --color-secondary-60: #f8a16b;
  --color-secondary-10: #fde0ce;
  --color-danger: #d5445c;
  --color-warning: #e9cf18;
  --color-success: #2dcc70;
  --color-info: #007ad5;
  --color-bgdanger: #f4d5d5;
  --color-bgwarning: #fbf5d1;
  --color-bgsuccess: #d5f5e2;
  --color-bginfo: #e5f1fa;
  --color-gray-100: #333333;
  --color-gray-60: rgba(51, 51, 51, 0.6);
  --color-gray-30: rgba(51, 51, 51, 0.3);
  --color-gray-10: rgba(51, 51, 51, 0.1);
  --color-graph-red: #f2554f;
  --color-state-up: #ef6854;
  --color-state-down: #5476ef;
  --color-state-unchanged: #5ac45d;

  --color-background: #efedec;

  /** spacings */
  --spacing-3xs: 4px;
  --spacing-xxs: 8px;
  --spacing-xs: 12px;
  --spacing-s: 16px;
  --spacing-m: 24px;
  --spacing-l: 32px;
  --spacing-xl: 40px;
  --spacing-xxl: 48px;
  --spacing-3xl: 64px;
  --spacing-4xl: 80px;

  /** font-family */
  --font-HiraginoSans: 'HiraginoSans', sans-serif;
  --font-HiraginoSansW2: 'HiraginoSans-W2', sans-serif;
  --font-HiraginoSansW3: 'HiraginoSans-W3', sans-serif;
  --font-HiraginoSansW4: 'HiraginoSans-W4', sans-serif;
  --font-HiraginoSansW5: 'HiraginoSans-W5', sans-serif;
  --font-HiraginoSansW6: 'HiraginoSans-W6', sans-serif;
  --font-NotoSans: 'Noto Sans JP', sans-serif;
  --font-JosefinSans: 'Josefin Sans', sans-serif;
  --font-SFUIText: 'SF UI Text', sans-serif;
}

body {
  font-family: var(--font-HiraginoSans);
  height: 100%;
  -webkit-touch-callout: none; /* iOSのwebkitの設定のため、必要に応じてAndroid用のプロパティも設定する(要調査) */
}

img {
  vertical-align: top; /* imgの下に余白ができるのを防ぐ */
  width: 100%;
}

p {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
  padding: 0;
}

hr {
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  appearance: none;
  outline: none;
  padding: 0;
}
