.fontOfferTitle {
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  color: var(--color-base-dark);
}

.fontOfferMessage {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: var(--color-base-dark);
}

.fontOfferExpirationDate {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  color: rgba(18, 18, 18, 0.6);
}

.offerTitle {
  composes: fontOfferTitle;
  margin-bottom: var(--spacing-3xs);
  white-space: pre-wrap;
}

.offerMessage {
  composes: fontOfferMessage;
  margin-bottom: var(--spacing-xxs);
}

.offerExpirationDate {
  composes: fontOfferExpirationDate;
  text-align: right;
}
