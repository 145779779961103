.topMessage {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-xl);
  margin: 0;
}

.helpLink {
  display: block;
  margin-left: auto;
  font-size: 12px;
  font-weight: bold;
  color: var(--color-primary-100);
  background-color: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
}

.errorMessage {
  font-size: 10px;
  color: var(--color-danger);
}

.contentArea {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 145px;
}

@media screen and (max-height: 280px) {
  .bottomContainer {
    display: none;
  }
}
