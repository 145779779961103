.clientCompanyContainer {
  background-color: var(--color-background);
  min-height: 100%;
  padding: var(--spacing-s) 0 150px;
}

.selectButton {
  background-image: url('global/images/icon-public.svg');
  width: 60px;
  height: 28px;
  background-color: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
}

.sectionWrapper {
  padding: 12px var(--spacing-s);
}

.sectionWrapperHighlight {
  background-color: #d5e9e6;
}

.sectionTitle {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);
}

.sectionTitleHighlight {
  color: #153f3b;
  font-family: var(--font-HiraginoSansW3);
}

.clientWrapper {
  background-color: var(--color-light);
  font-family: var(--font-HiraginoSansW3);
  padding: 20px var(--spacing-s);
}

.clientCompanyName {
  font-size: 14px;
  color: var(--color-base-dark);
  margin-bottom: 6px;
  padding-right: 40px;
}

.clientInfoWrapper {
  position: relative;
  text-decoration: none;
  width: 80%;
}

.clientInfoWrapper::after {
  content: '';
  display: inline-block;
  background-image: url('../../../../global/images/icon-company.svg');
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
}

.clientMajorBrands {
  color: var(--color-base-dark-sub);
  font-size: 12px;
  padding-right: 40px;
}

.dividerWrapper {
  background-color: var(--color-light);
  padding-left: var(--spacing-s);
}

.toggleSwitchText {
  font-size: 12px;
  font-family: var(--font-HiraginoSansW3);
  padding-right: var(--spacing-xxs);
  color: var(--color-base-dark-sub);
}
