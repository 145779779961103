.topNoticeText {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.container {
  background-color: var(--color-background);
  min-height: 100%;
  padding: var(--spacing-s);
}

.contentsTitle {
  font-size: 18px;
  font-weight: bold;
  margin: var(--spacing-s) 0;
}

.contentsDescription {
  margin-bottom: 28px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}

.emptyJoinedProgram {
  margin-top: var(--spacing-m);
  width: 100%;
  height: 236px;
  text-align: center;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: var(--color-base-dark);
}
