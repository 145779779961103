.parent {
  position: relative;
  margin-top: 6px;
  margin-right: 6px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #fee455;
}

.child {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #fed33c;
}

.string {
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  position: absolute;
  font-size: 12px;
  color: #ffffff;
  font-weight: 900;
}
