.slide {
  margin: var(--spacing-m) var(--spacing-s) var(--spacing-xl) var(--spacing-s);
  height: 380px;
}

.dialogContentsRoot {
  padding-top: var(--spacing-m);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  max-width: 312px;
  margin-bottom: var(--spacing-l);
}

.title {
  font-family: var(--font-NotoSans);
  font-size: 22px;
  font-weight: medium;
  color: #ef6854;
  text-align: center;
}

@media screen and (max-height: 570px) {
  .slide {
    height: 330px;
  }

  .dialogContentsRoot {
    padding-top: var(--spacing-m);
  }

  .imageContainer {
    width: 270px;
    margin-bottom: var(--spacing-m);
  }

  .title {
    font-size: 20px;
  }
}

.description {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  white-space: pre-wrap;
}

.primaryButton {
  margin: var(--spacing-xxs) var(--spacing-s) var(--spacing-m) var(--spacing-s);
}
