input[type='text'] {
  border: none;
  outline: 0;
  color: var(--color-base-dark);
  font-family: var(--font-JosefinSans);
  font-weight: 300;
  letter-spacing: 0.06px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.inputTextLarge {
  font-size: 24px;
  line-height: 1.4;
  padding: 0 20px;
}

.inputTextMedium {
  font-size: 14px;
  line-height: 1.7;
  padding: 2px 16px 22px;
}

input[type='text']::placeholder {
  color: rgba(18, 18, 18, 0.3);
}
