select {
  appearance: none;
  background: inherit;
  border: none;
  color: var(--color-base-dark);
  outline: 0;
  font-family: var(--font-JosefinSans);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.06px;
  line-height: 1.7;
  padding: 2px 42px 22px 16px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;

  /* 矢印アイコン部分 */
  background-image: url('global/images/arrow-single-down.svg');
  background-position: right 16px top 0;
  background-repeat: no-repeat;
  background-size: 20px;
}

option:disabled {
  background: inherit;
  color: var(--color-base-dark);
}

.selectPlaceholder {
  color: rgba(18, 18, 18, 0.3);
}
