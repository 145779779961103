.profileDetailPageWrapper {
  padding: 60px var(--spacing-s) 150px;
}

.sectionTitle {
  font-size: 16px;
  font-family: var(--font-HiraginoSansW6);
  margin-bottom: var(--spacing-m);
}

.profilePageDescription {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.8;
  text-align: center;
}

.profilePageDescriptionWarning {
  color: var(--color-danger);
}
