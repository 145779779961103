.errorMessage {
  font-size: 10px;
  color: var(--color-danger);
}

.message {
  font-size: 12px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
  line-height: 22px;
}
