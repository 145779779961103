.container {
  height: 60px;
  margin: 0 var(--spacing-s);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  color: #242424;
}
