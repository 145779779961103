.profilePageWrapper {
  padding: 60px var(--spacing-s) 150px;
}

.profilePageDescription {
  font-family: var(--font-HiraginoSansW3);
  font-size: 12px;
  line-height: 1.8;
  text-align: center;
}

.profilePageAttentionDescription {
  line-height: 1.8;
}

.profilePageDescriptionWarning {
  color: var(--color-danger);
}
