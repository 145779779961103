.pieChartWrapper {
  color: var(--color-gray-60);
  font-family: var(--font-NotoSans);
  height: 50%;
  width: 50%;
  max-width: 300px;
  min-width: 135px;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.pieChartDetail {
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.pieChartTiele {
  margin-bottom: 10px;
}

.scoreValueWrapper {
  font-family: var(--font-JosefinSans);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 18px;
}

.scoreValue {
  color: var(--color-base-dark);
  font-size: 40px;
  font-weight: 300;
  line-height: 0.5;
  margin-left: 8px;
  padding: 2px 0;
}

@media screen and (max-width: 320px) {
  .scoreValue {
    font-size: 32px;
  }
}

.scoreRankWrapper {
  display: inline-block;
  font-family: var(--font-JosefinSans);
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
  position: relative;
  width: 26px;
}

.scoreTrendWrapper {
  position: absolute;
  top: -16px;
  right: -16px;
}

.lastUpdatedAt {
  font-family: var(--font-SFUIText);
  font-size: 11px;
  font-weight: 500;
}
