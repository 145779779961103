.container {
  padding: var(--spacing-m) var(--spacing-s) var(--spacing-3xl) var(--spacing-s);
}

.title {
  font-family: var(--font-HiraginoSansW6);
  font-size: 18px;
  color: var(--color-base-dark);
}

.description {
  margin: var(--spacing-m) 0 20px;
  font-family: var(--font-HiraginoSansW3);
  font-size: 14px;
  line-height: 1.71;
  white-space: pre-wrap;
}

.checksRoot {
  display: flex;
  flex-direction: column;
}

.check {
  display: flex;
  align-items: center;
}

.opinionAndRequestTitle {
  margin-top: 20px;
  font-family: var(--font-HiraginoSansW6);
  font-size: 16px;
  color: var(--color-base-dark);
}

.opinionAndRequest {
  margin-top: var(--spacing-xxs);
  padding: var(--spacing-xxs);
  resize: none;
  box-sizing: border-box;
  width: 100%;
  height: 192px;
  border-radius: 8px;
  border: solid 1.5px #acb1c0;
  background: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.opinionAndRequest:focus {
  outline: none;
}

.button {
  margin-top: var(--spacing-4xl);
}

.errorMessage {
  font-size: 12px;
  color: var(--color-danger);
}
