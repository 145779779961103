.questionContainer {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px 0 rgba(35, 35, 57, 0.1);
  border-radius: 8px;
  color: var(--color-base-dark);
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-s);
}

.questionIcon {
  background-color: var(--color-light-sub);
  margin: auto 0;
  width: 48px;
}

@media screen and (max-width: 320px) {
  .questionIcon {
    height: 34px;
    width: 34px;
  }
}

.questionDetail {
  padding-left: 10px;
  padding-right: 10px;
  color: var(--color-base-dark);
  min-width: 40%;
}

.questionName {
  font-family: var(--font-HiraginoSansW6);
  font-size: 14px;
  line-height: 1.71;
  white-space: pre-wrap;
}

.questionProgress {
  font-family: var(--font-JosefinSans);
  font-size: 40px;
  font-weight: 300;
  height: 30px;
  display: flex;
  align-items: baseline;
}

.questionTotal {
  color: var(--color-base-dark-sub);
  font-size: 20px;
  padding-top: 15px;
  padding-left: 2px;
}

.questionTotalUnit {
  color: var(--color-base-dark-sub);
  padding-left: 2px;
  padding-top: 15px;
  font-size: 12px;
  font-family: var(--font-HiraginoSansW2);
}

.questionButton {
  margin-left: auto;
}

.questionDisableButton {
  margin-top: 4px;
}

.questionEnableButton {
  margin-top: 4px;
}

.questionButtonPointLabel {
  margin-top: 4px;
  font-size: 12px;
  font-family: var(--font-HiraginoSansW6);
  text-align: center;
}

@media screen and (max-width: 320px) {
  .questionButtonPointLabel {
    font-size: 10px;
  }
}
