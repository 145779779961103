.progressBarContainer {
  background-color: var(--color-surface);
  color: var(--color-base-dark);
  font-family: 'HiraginoSans-W2', sans-serif;
  font-size: 12px;
  padding: 10px var(--spacing-s);
  text-align: right;
}

.itemStep {
  font-family: var(--font-JosefinSans);
  font-size: 18px;
  font-weight: 300;
  margin: 0 14px 0 4px;
}

.itemStepStatusWrapper {
  font-family: var(--font-JosefinSans);
  font-size: 18px;
  font-weight: 300;
}

.itemCurrentStep {
  font-size: 32px;
}

.itemMaxStep {
  margin-left: 2px;
  color: var(--color-base-dark-sub);
}

.progressBar {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  display: block;
  width: 100%;
  height: 5px;
}

::-webkit-progress-bar {
  background-color: #f1eff5;
}

::-webkit-progress-value {
  background-color: var(--color-primary-100);
}

::-moz-progress-bar {
  background-color: var(--color-primary-100);
}
