.globalNavigation {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 0 7px 0 rgba(0, 89, 141, 0.1);
  z-index: 100;
  background-color: var(--color-light);
}

.navigationItem {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border: none;
  appearance: none;
  padding: 0;
  background-color: var(--color-light);
}

.icon {
  width: 32px;
  height: 32px;
  margin-top: 6px;
  background-color: var(--color-base-disabled);
}

.icon.home {
  mask-image: url(./images/icon-home.svg);
}

.icon.offer {
  mask-image: url(./images/icon-offer.svg);
}

.icon.program {
  mask-image: url(./images/icon-program.svg);
}

.icon.mydata {
  mask-image: url(./images/icon-mydata.svg);
}

.icon.selfCheck {
  mask-image: url(./images/icon-self-check.svg);
}

.navigationItem.itemSelected .icon {
  background-color: #003543;
}

.title {
  font-size: 10px;
  color: var(--color-base-disabled);
  margin-top: 5px;
  white-space: pre-wrap;
  line-height: 1;
}

.navigationItem.itemSelected .title {
  color: #003543;
}

.batch {
  position: absolute;
  top: 2px;
  right: 10px;
  color: var(--color-light);
  background-color: #fb3737;
  font-size: 12px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 20px;
  border: 2px solid var(--color-light);
}

.batch.largeNumber {
  right: 0;
  width: 30px;
}
