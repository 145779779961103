.topMessage {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  padding-top: var(--spacing-m);
  margin: 0;
}

.highlightText {
  font-size: 12px;
  text-align: center;
  color: var(--color-primary-100);
}

.text {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.errorMessage {
  font-size: 10px;
  color: var(--color-danger);
}
