.checkbox {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  appearance: none;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.checkbox.checked {
  background-image: url('./icon-checked.svg');
}

.checkbox.unchecked {
  background-image: url('./icon-unchecked.svg');
}
